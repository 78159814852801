.superOfferSection {
    min-height: 300px !important;
    margin-bottom: 30px !important;
}
.slick-slider .slick-track, .slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
    margin-top: -10px;
}
.overrideMe {
    width: 250px;
    /* word-break: break-all;
    overflow-wrap: break-word; */
    overflow-wrap: break-word;
    display: block;
  
    color:  #fff;
    background:#0074B7;  word-break: break-all; 
  
    height:auto;
    /* background-color: #010101  !important; */
    text-align: justify;
    font-weight: 500;
    z-index: 1000;
}

.SuperlandingCard {
    border-radius: 10px;
    box-shadow: 0 3px 30px 0 rgb(0 0 0 / 10%);
    background-color: #ffffff;
    padding: 25px 25px 25px;   
}
.SuperlandingCard h2{
    font-weight: 700;
    font-size: 32px; margin-bottom: 10px;
}
.appendBottom20 {
    margin-bottom: 20px;
}
.appendBottom20 {
    margin-bottom: 20px;
}

.SuperFlex {
    display: flex;
}
.blackText {
    color: #000;
}
.latoBold {
    font-weight: 700;
}

.SuperlandingCard .SuperFlex.perfectCenter {
    align-items: flex-start;
    justify-content: left;
}

.appendLeft30 {
    margin-left: 30px;
}

.superOffersTab {
    border-bottom: solid 1px #c6c6c6;
}

.superOffersTab li.active {
    border-bottom: solid 3px #008cff;
    background-color: transparent;
}
.superOffersTab li {
    margin-right: 15px;
    border-bottom: solid 3px #fff;
    transition: all 0.2s linear;
    position: relative;
}
.superOffersTab li.active a {
    color: #008cff;
}

.SuperFlex.hrtlCenter {
    align-items: center;
}
.SuperFlex.column {
    flex-direction: column;
}
/* .SuperlandingCardSlider {
    margin-right: -40px;
    margin-left: -12px;
} */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    height:250px;
    padding: 0;
}
.SuperlandingCardSlider .slick-slider .slick-prev {
    right: 69px;
}
.SuperlandingCardSlider .slick-slider .slick-prev {
    width: 36px;
    height: 36px;
    border-radius: 20px 0 0 20px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px #f1f1f1;
    border-right: none;
    box-shadow: -1px 0px 3px 0 rgb(0 0 0 / 10%);
}
.page-section {
    width: 1200px;
    margin: 0 auto;
    margin-bottom: 50px;
}
.SuperlandingCardSlider .slick-slider .slick-prev {
    right: 69px;
}

.SuperlandingCardSlider .slick-slider .slick-prev {
    width: 36px;
    height: 36px;
    border-radius: 20px 0 0 20px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px #f1f1f1;
    border-right: none;
    box-shadow: -1px 0px 3px 0 rgb(0 0 0 / 10%);
}
.SuperlandingCardSlider .slick-slider button {
    position: absolute;
    top: -35px !important;
    width: 40px;
    height: 40px;
    background: transparent;
    box-shadow: none;
    font-size: 0;
    outline: none;
    border: none;
    cursor: pointer;
    border-radius: 0;
    border-color: #f1f1f1 !important;
}

.SuperlandingCardSlider .slick-disabled.slick-next:before, .SuperlandingCardSlider .slick-disabled.slick-prev:before {
    opacity: 0.3;
}

.SuperlandingCardSlider .slick-prev:before {
 transform: rotate(135deg) !important;
    -webkit-transform: rotate(
135deg
);
    position: relative;
    right: 0;
    top: 0px;
    content: "";
}
.SuperlandingCardSlider .slick-next:before, .SuperlandingCardSlider .slick-prev:before {
    border: solid #008cff;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;
    transform: rotate(
-45deg
);
    -webkit-transform: rotate(
-45deg
);
    position: relative;
    right: 0;
    top: 0px;
    content: "";
}


.SuperlandingCardSlider .slick-slider .slick-next {
    width: 36px;
    height: 36px;
    border-radius: 0px 20px 20px 0px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px #f1f1f1;
    border-left: none;
    box-shadow: 2px 0px 3px 0 rgb(0 0 0 / 10%);
    z-index: 0;
    filter: none;
}
slick-slider .slick-track, .slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
}
.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before, .slick-track:after {
    display: table;
    content: '';
}

.slick-initialized .slick-slide {
    display: flex;
    align-items: center;
    justify-content: center;
}
.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}
.slick-initialized .slick-slide > div {
    display: inline-flex;
    width: 100%;
}
.SuperlandingCard .SuperlandingCardSlider .slideItem {
    padding: 0px;
    border: none;
    outline: none;
}
.SuperlandingCardSlider .slideItem .itemCard {
    border-radius: 6px;
    box-shadow: 0 1px 6px 0 rgb(0 0 0 / 20%);
    background-color: #ffffff;
    margin: 5px 10px 5px 2px;
    padding: 0px;
    /* cursor: pointer; */
}
.soCardHeight {
    min-height: 150px !important;
}

.cardInnerInfo {
    min-height: inherit !important;
}

.SuperlandingCardSlider .slideItem .itemThumb {
    width: 150px;
    margin-top: 10px;
}

.SuperlandingCardSlider .slideItem .itemImgWrap {
    width: 100%;
    height: 115px;
    border-radius: 10px;
    background: linear-gradient( 
90deg , #dfdddf, #efefef);
    background-size: 400% 400px;
    margin-left: 18px;
}
.slick-initialized .slick-slide {
    display: block;
    padding: 15px 4px !important;
}
.makeRelative {
    position: relative;
}

.SuperlandingCardSlider .slideItem .itemThumb img {
    border-radius: 10px;
    height: 115px;
}

.slick-slide img {
    display: block;
    width: 100%;
    max-height: 135px;
}

.SuperlandingCardSlider .slideItem .itemDesc {
    /* width: 290px; */
    max-width: 100%;
    margin: 10px 10px 10px 0px;
}

p.makeFlex.hrtlCenter.right.appendBottom10.directionRtl{
    margin: 0px;
    display: flex;
}
.SuperFlex.left {
    justify-content: flex-start;
}


.redBdr {
    width: 100px;
    height: 1px;
    background-color: #e1e1e1;
    display: block;
}
.redBdr em {
    width: 60px;
    height: 2px;
    background-color: #deb956;
    display: block;
    margin: auto;
}
.appendBottom10 {
    margin-bottom: 10px;
}
.greyText {
    border-color: #9b9b9b;
}

.SuperFlex.hrtlCenter {
    align-items: center;
}
.tcApplyText {
    width: 120px;
    text-align: center;
}
.darkGreyText {
    color: #4a4a4a;
}
.SuperFlex.spaceBetween {
    justify-content: space-between;
}

.SuperFlex .flexOne {
    flex: 1;
}
.flexOne {
    flex: 1;
}
.pushRight {
    margin-left: auto;
}

.pushRight a {
    color: #008cff;
    cursor: pointer;
}

.CouponDetail{
    font-size: 12px;
}


.buildTripSection {
    min-height: 400px !important;
    margin-bottom: 50px !important;
    margin-top: 40px;
}
.BuildlandingCard {
    border-radius: 16px;
    box-shadow: 0 3px 30px 0 rgb(0 0 0 / 10%);
    padding: 25px 40px 40px;
    
}

.BuildContent{
    display:flex;
    flex-direction: row;
    justify-content:space-between;
}
.wid-pages{    width: 900px;
    margin: 0px auto;
    padding: 41px;
    background-color: #f1f1f175;
    border-radius: 10px}
.wid-pages p{    font-size: 16px;
    margin-top: 15px;}
.buildBackgroundImg{
    background:linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2)),url(../assets/images/travel.jpg) center center no-repeat;
	-webkit-background-size:cover;
	-moz-background-size:cover;
	-o-background-size:cover;
	background-size:cover;background-size: cover;
    padding: 100px 0px !important;
    background-position: bottom;
}

.BuildHeader{
    width:500px;
    justify-content: left;
    align-items: flex-start;
    margin-top: 50px;
    text-align:left;
}

.BuildHeader p{
    font-size: 13px;
    color: #000;
    width:450px;
    text-align: left;
    font-family: 'Arimo', sans-serif;
}

.BuildHeader h2{
    font-family: 'Arimo', sans-serif;
    font-weight: 700;
}

a.btn_1.rounded, .btn_1.rounded {
    border-radius: 6px !important;
    float: none;
    outline: none;
}

.superOffersTab li a span{
    font-size: 12px;
}
.__react_component_tooltip {
    border-radius: 3px;
    display: inline-block;
    font-size: 13px;
    left: -999em;
    opacity: 0;
    padding: 8px 21px;
    position: fixed;
    pointer-events: none;
    transition: opacity 0.3s ease-out;
    top: -999em;
    visibility: hidden;
    z-index: 9999999999 !important;
}



a.btn_1, .btn_1 {
    border: none;
    color: #fff;
    background: #ff904a !important;;
    outline: none;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    padding: 15px 30px;
    color: #fff;
    font-weight: normal;
    text-align: center;
    line-height: 1;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    float: right;
    font-family: 'Arimo', sans-serif;
}

a.btn_1:hover, .btn_1:hover {
	background:#7a802b;
    color: #fff;
}

.Superdesc p{
    font-size: 13px;
    color: #000;
    text-align: left;
    font-family: 'Arimo', sans-serif;
    font-weight: 400;
    margin-bottom: 0;
}

@media only screen and (min-width:991px) and (max-width:1199px){
    .wid-pages {
        width:100%;
        margin: 0px auto;
        padding: 21px;
        background-color: #f1f1f175;
        border-radius: 10px;
    }
    .SuperlandingCard h2 {
        font-weight: 600;
        font-size: 25px;
    }
    .superOffersTab li a span {
        font-size: 10px;
        font-weight: 600;
    }
    .font18 {
        font-size: 14px;
        line-height: 14px;
    }
}

@media only screen and (min-width:768px) and (max-width: 990px){
    .wid-pages {
        width:100%;
        margin: 0px auto;
        padding: 21px;
        background-color: #f1f1f175;
        border-radius: 10px;
    }
    .wid-pages {
        width:100%;
        margin: 0px auto;
        padding: 21px;
        background-color: #f1f1f175;
        border-radius: 10px;
    }
    .SuperlandingCard h2 {
        font-weight: 600;
        font-size: 25px;
    }
    .superOffersTab li a span {
        font-size: 10px;
        font-weight: 600;
    }
    .font18 {
        font-size: 14px;
        line-height: 14px;
    }
}

@media only screen and (min-width:480px) and (max-width: 767px){
    .wid-pages {
        width:100%;
        margin: 0px auto;
        padding: 21px;
        background-color: #f1f1f175;
        border-radius: 10px;
    }
   
    .SuperlandingCard h2 {
        font-weight: 600;
        font-size: 25px;
    }
    .superOffersTab li a span {
        font-size: 10px;
        font-weight: 600;
    }
    .font18 {
        font-size: 14px;
        line-height: 14px;
    }
    .SuperlandingCardSlider {
        margin-right: 0px;
        margin-left: 0px;
    }
    .SuperlandingCardSlider .slideItem .itemCard {
        border-radius: 6px;
        box-shadow: 0 1px 6px 0 rgb(0 0 0 / 20%);
        background-color: #ffffff;
        margin: 5px 10px 5px 2px;
        padding: 0px;
        cursor: pointer;
    }
    .SuperlandingCardSlider .slideItem .itemThumb {
        max-width: 100% !important;
        margin: 10!important;
    }
}

@media only screen and (max-width: 479px){
    .wid-pages {
        width:100%;
        margin: 0px auto;
        padding: 21px;
        background-color: #f1f1f175;
        border-radius: 10px;
    }
    .SuperlandingCardSlider .slideItem .itemCard {
        border-radius: 6px;
        box-shadow: 0 1px 6px 0 rgb(0 0 0 / 20%);
        background-color: #ffffff;
        margin: 5px 10px 5px 2px;
        padding: 0px;
        cursor: pointer;
    }
    .SuperlandingCardSlider .slideItem .itemThumb {
        width: 100% !important;
        margin: 10px!important;
    }
    .SuperlandingCard h2 {
        font-weight: 700;
        font-size: 18px;
        margin-bottom: 10px;
    }
    .SuperlandingCard {
        border-radius: 10px;
        box-shadow: 0 3px 30px 0 rgb(0 0 0 / 10%);
        background-color: #ffffff;
        padding: 20px 12px 30px 12px;
        height: 100vh;
        display: block;
    }
    .Superdesc p {
        font-size: 13px;
        color: #000;
        text-align: left;
        font-family: 'Arimo', sans-serif;
        font-weight: 400;
        margin-bottom: 0;
        width: 90%;
    }
    .SuperlandingCardSlider {
        margin-right: 0px;
        margin-left: 0px;
    }
    .capText {
        text-transform: capitalize !important;
    }

   
}
.containerFrom{
    display: flex !important;
  }
  .containerFromAmount{
      display: flex !important;
  }
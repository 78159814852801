.contentWrapper--myProfile {
    margin-top: -110px;
}
.ProfilemakeFlex.ProfilespaceBetween {
    justify-content: space-between;
}
.ProfilemakeFlex{
    display: flex;
}

.contentWrapper {
    width: 1200px;
    margin: -75px auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.leftSecWrapper {
    width: 283px;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.profilSdeBar--sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 15px;
}
.profilSdeBar {
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    -webkit-box-shadow: 0 3px 30px 0 rgb(0 0 0 / 10%);
    -moz-box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 3px 30px 0 rgb(0 0 0 / 10%);
    background-color: #fff;
    padding: 32px 0;
}

.profilSdeBar .proflPicContanr {
    margin: 0 auto;
}

.proflPicContanr {
    width: 50px;
    height: 50px;
    background-image: linear-gradient( 
45deg
 , #ace143, #219393);
    border-radius: 50%;
    position: relative;
}
.proflPicContanr img{
    border-radius: 50%;
}

.proflPicContanrNew {
    width: 90px;
    height: 90px;
    background-image: linear-gradient( 
45deg
 , #ace143, #219393);
    border-radius: 50%;
    position: relative;
}
.proflPicContanr__nmeIniContanr {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.proflPicContanr__nmeIni {
    font-size: 40px;
}

.profilSdeBar__contntWrap {
    text-align: center;
    padding: 0 20px;
    margin-top: 20px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.myProfilePage .rightSecWrapper {
    margin-bottom: 60vh;
}

.rightSecWrapper {
    margin: 0 auto;
    width: 720px;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.cm__myProfileTheme {
    margin-bottom: 12px;
}
.proflCmpltonStats {
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    -webkit-box-shadow: 0 3px 30px 0 rgb(0 0 0 / 10%);
    -moz-box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 3px 30px 0 rgb(0 0 0 / 10%);
    background-color: #fff;
    padding: 20px 30px;
    position: relative;
}

.proflCmpltonStats__prcntagSec {
    width: 280px;
}

.darkGreyText {
    color: #4a4a4a ; 
}
.proflCmpltonStats__prcntagSec {
    width: 280px;
}

.proflCmpltonStats__progrsBar {
    width: 100%;
    height: 6px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    background-color: #e2e2e2;
    position: relative;
    margin-top: 7px;
}

.proflCmpltonStats__progrsBarFill--complete100 {
    width: 100%;
}

.proflCmpltonStats__progrsBarFill {
    width: 0%;
    height: 100%;
    -webkit-border-radius: inherit;
    -moz-border-radius: inherit;
    border-radius: inherit;
    background-color: #31b79c;
}
.proflCmpltonStats__stpsStatusLst {
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    background-color: #eff3f7;
    padding: 18px 20px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 27px;
}

.proflCmpltonStats__stpsStatusItm {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}



.myPrfilSprit {
    background-image: url(https://imgak.mmtcdn.com/pwa_v3/pwa_commons_assets/desktop/usersProfile/myProfileSprite.png);
    -webkit-background-size: 200px 200px;
    -moz-background-size: 200px 200px;
    background-size: 200px 200px;
    background-repeat: no-repeat;
    display: inline-block;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.myPrfilSprit__verifiedBig {
    width: 24px;
    height: 24px;
    background-position: -87px -80px;
}
.cm__myProfileTheme {
    margin-bottom: 12px;
}

.cm__myTripSideBar {
    position: relative;
}
.profilCompont {
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    border: solid 1px #cacaca;
    background-color: #fff;
    padding: 20px 30px 2px;
}

.cm__myTripSideBar::before {
    content: '';
    width: 6px;
    height: 50px;
    background-color:#1885cb;;
    position: absolute;
    top: 28px;
    left: 0;
}
.detalCrdHadr {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.font30 {
    font-size: 30px;
    line-height: 30px;
}
.font18 {
    font-size: 18px;
    line-height: 18px;
}
.btn__dtailAdEdt {
    font-family: inherit;
    display: inline-flex;
    align-items: center;
    padding: 5px 15px;
    background: #fff;
    flex-shrink: 0;
    border: 1px solid #1885cb;
    border-radius: 3px;
    outline: 0;
    cursor: pointer;

}
.btn__dtailAdEdt:hover{
    font-family: inherit;
    display: inline-flex;
    align-items: center;
    padding: 5px 15px;
    background: #1885cb;
    border-radius: 3px;
    flex-shrink: 0; 
    border: 1px solid #fff;
    color:#fff !important;
    outline: 0 !important;
    cursor: pointer;
}
.btn__dtailAdSave {
    font-family: inherit;
    display: inline-flex;
    align-items: center;
    padding: 7px 21px;
    background: #31b79c;
    flex-shrink: 0;
    border: 1px solid #31b79c;
    color: #fff;
    border-radius: 3px;
    outline: 0;
    cursor: pointer;
    margin-bottom: 25px;

}
.react-date-picker__button {
    border: 0;
    background: transparent;
    padding: 4px 6px;
    /* color: blue; */
    outline: 0 !important;
}
.react-date-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border: none !important;
}

.react-date-picker__inputGroup__input {
    min-width: 0.54em;
    height: 100%;
    position: relative;
    padding: 0 1px;
    border: 0;
    background: none;
    font: inherit;
    box-sizing: content-box;
    -moz-appearance: textfield;
    outline: 0;
}

.react-calendar {
    width: 300px !important;
    padding: 10px;
    max-width: 100%;
    background: white;
    border: none !important;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    border-radius: 5px;
    -webkit-box-shadow: 0 3px 2px -2px rgb(0 0 0 / 7%), 0 1px 5px 0 rgb(74 74 74 / 20%), 0 2px 2px 0 rgb(74 74 74 / 20%);
    -moz-box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.07), 0 1px 5px 0 rgba(74, 74, 74, 0.2), 0 2px 2px 0 rgba(74, 74, 74, 0.2);
    box-shadow: 0 3px 2px -2px rgb(0 0 0 / 7%), 0 1px 5px 0 rgb(74 74 74 / 20%), 0 2px 2px 0 rgb(74 74 74 / 20%);
    background-color: #fff;
}

.react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
    background-color: #4aabf71c !important;
    border-radius: 5px;
}

.react-calendar__tile--active {
    background: #006edc;
    color: white;
    border-radius: 5px !important;
}
.react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
    width:100%;
}
.myPrfilSprit__edtIcn {
    width: 15px;
    height: 15px;
    background-position: -8px -5px;
}
.profilCompont__dtalLst {
    margin: 35px -30px 0;
    -webkit-border-radius: 0 0 10px 10px;
    -moz-border-radius: 0 0 10px 10px;
    border-radius: 0 0 10px 10px;
    overflow: hidden;
}
.profilCompont__dtalLstItm:first-child .profilCompont__dtalLstItmContnt {
    border-top: 0;
}

.profilCompont__dtalLstItmContnt {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 18px 0;
    margin-left: 30px;
    border-top: 1px solid #e3e3e3;
    height: 55px;
}
.profilCompont__detalkey {
    width: 160px;
    margin-right: 20px;
}

.cm__myProfileTheme {
    margin-bottom: 12px;
}

.cm__myTripSideBar {
    position: relative;
}
.lognDtalComp {
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    border: solid 1px #cacaca;
    background-color: #fff;
    padding: 20px 30px 2px;
}
.detalCrdHadr {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.lognDtalComp__dtalLst {
    margin: 35px -30px 0;
    -webkit-border-radius: 0 0 10px 10px;
    -moz-border-radius: 0 0 10px 10px;
    border-radius: 0 0 10px 10px;
    overflow: hidden;
}

.lognDtalComp__dtalLstItm:first-child .lognDtalComp__dtalLstItmContnt {
    border-top: 0;
}
.lognDtalComp__dtalLstItmContnt {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 18px 0;
    margin-left: 30px;
    border-top: 1px solid #e3e3e3;
    height: 55px;
}

.lognDtalComp__detalkey {
    width: 160px;
    margin-right: 20px;
}

.lognDtalComp__editDeltContanr {
    margin-left: auto;
    margin-right: 30px;
    display: none;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.lognDtalComp__dltBtn {
    position: relative;
    outline: 0;
}
.myPrfilSprit__delete {
    width: 18px;
    height: 18px;
    background-position: -29px -5px;
}

.myPrfilSprit__notverifiedBig {
    width: 24px;
    height: 24px;
    background-position: -33px -104px;
}



.txtFild__rightpasswordIcon {
    position: absolute;
    top: 65%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 10px;
    cursor: pointer;
}

.myPrfilSprit__hidePswrd {
    width: 24px;
    height: 16px;
    background-position: -118px -104px;
}

.myPrfilSprit__showPswrd {
    width: 24px;
    height: 16px;
    background-position: -118px -85px;
}
.myPrfilSprit {
    background-image: url(https://imgak.mmtcdn.com/pwa_v3/pwa_commons_assets/desktop/usersProfile/myProfileSprite.png);
    -webkit-background-size: 200px 200px;
    -moz-background-size: 200px 200px;
    background-size: 200px 200px;
    background-repeat: no-repeat;
    display: inline-block;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}
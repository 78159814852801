.content-inputs{
    padding: 110px;
    margin-top: 200px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin-bottom: 200px;
    align-items: center;
    /* background-color:rgba(0,0,0,0.6); */
    height: auto;
}

.parallax {
    background-image: url("../../assets/images/background-images/travel-pattern-with-trip.jpg");  
    min-height: 500px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

.content-inputs input{
    margin: 10px;
}

.content-inputs input[type="text"]{
    padding: 15px 20px;
    width: 650px;
    border-radius: 6px;
    outline: none;
}
.content-inputs .continue-plan{
    padding: 10px 22px;
    border: none;
    border-radius: 20px;
    background-color: green;
    color: white;
    font-weight: 600;
    font-size: larger;
}

.guests-input{
    width: 500px !important; 
    height: 200px;
    background-color: white;
    margin-top: 0;
}

.selection{
    background-color: white;
    height: auto !important;
    display: flex;
    width: 500px;
    height: 200px;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-flow: column;
}
.selection .tile-img{
    border-radius: 100px;
    height: auto;
}



.selection .tile-img:hover{
    opacity: 0.8;
    transition: all ease-in-out 0.3s;
    cursor: pointer;
}

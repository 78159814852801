.htSJWp {
  color: rgb(255, 255, 255);
  font-family: Lato, sans-serif;
  background-image: linear-gradient(180deg, #fff, #fff, #ffffff, #fff);
  font-size: 14px;
  margin-top: 45px;
}

.IPGtP {
  background-color: rgb(33, 33, 34);
  transition: all 0.3s linear 0s;
  height: 225px;
}

.wrapper {
  max-width: 1180px;
  margin: 0px auto;
  padding: 60px 0px;
}

.idHzDq {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 40px;
}
.idHzDq > li {
  margin-right: 10px;
  font-size: 12px;
  font-weight: 700;
  color: rgb(231, 231, 231);
}
.idHzDq > li > a {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
}

.whiteText {
  color: #fff;
}
.mb10 {
  margin-bottom: 10px;
}
.font36 {
  font-size: 36px;
  line-height: 38px;
  font-weight: bold;
}
.whiteText {
  color: #fff;
}
.font16 {
  font-size: 16px;
  line-height: 16px;
}
.dDCOoG {
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.gjMtnc {
  width: 380px;
  border-radius: 10px;
  box-shadow: 0 3px 30px 0 rgb(0 0 0 / 10%);
  background-color: #ffffff;
  padding: 20px 20px 15px;
  flex-shrink: 0;
  transition: all 0.3s ease 0s;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.hyLvbp {
  height: 228px;
  overflow: hidden;
  border-radius: 10px 10px 0px 0px;
  margin-bottom: 22px;
  border: 0.5px solid rgba(255, 255, 255, 0.2);
}
.fBVWBY {
  height: 100%;
  border: 0px;
  outline: 0px;
}
.fBVWBY > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  vertical-align: top;
}

.TDHeaderContainer__Container-sc-11jrbqt-0 img {
  border-radius: 10px;
  box-shadow: 0 3px 30px 0 rgb(0 0 0 / 10%);
  background-color: #ffffff;
}

.fBVWBY > img {
  transition: 0.3s;
}

.fBVWBY > img:hover {
  transform: scale(1.5);
}
a,
a:focus,
a:hover {
  text-decoration: none;
}
.gMbbQW {
  font-size: 22px;
  font-weight: 900;
  color: rgb(2 2 2);
  display: flex;
  margin-bottom: 3px;
  text-transform: capitalize;
}
.gMbbQW > span {
  width: 30px;
  height: 24px;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px 0px;
  background-color: rgb(36, 153, 149);
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 900;
  color: rgb(255 255 255);
  margin-right: 7px;
  flex-shrink: 0;
  margin-top: 3px;
}

.blmrSB {
  font-size: 12px;
  color: rgb(155, 155, 155);
  margin-bottom: 10px;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.knmWQN {
  font-size: 16px;
  color: #3e3b3bcc;
  margin-bottom: 36px;
  flex: 1 1 0%;
}
.dsAebn {
  text-align: right;
  padding-top: 16px;
}
.gjMtnc {
  width: 380px;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 6px 0px, rgb(0 0 0 / 50%) 0px 2px 4px 0px;
  background-color: rgb(255 255 255);
  padding: 20px 20px 15px;
  flex-shrink: 0;
  transition: all 0.3s ease 0s;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.hyLvbp {
  height: 228px;
  overflow: hidden;
  border-radius: 10px 10px 0px 0px;
  margin-bottom: 22px;
  border: 0.5px solid rgba(255, 255, 255, 0.2);
}
.fBVWBY {
  height: 100%;
  border: 0px;
  outline: 0px;
}
.ihjtlI {
  height: 514px;
  padding: 20px 0px;
  position: relative;
  background: url(https://hblimg.mmtcdn.com/content/hubble/img/landsdown/mmt/destination/m_destination-lansdowne-landscape_l_400_640.jpg)
    center center / 100% no-repeat;
}
.jZqGFq {
  width: 1180px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  z-index: 1;
}
.hzCYLm {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
}
.hzCYLm > li {
  margin-right: 10px;
  font-size: 12px;
  font-weight: 700;
  color: #3e3b3bcc;
}
ul,
li {
  list-style-type: none;
}
.hzCYLm > li > a {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
}
.cuPQgq {
  display: flex;
  flex: 1 1 0%;
  -webkit-box-pack: justify;
  justify-content: space-between;
}
.KXLh {
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
}
.KXLh h1 {
  font-size: 50px;
  font-weight: 900;
  margin-bottom: 5px;
  color: #3e3b3bcc;
}
.KXLh p {
  font-size: 18px;
  line-height: 22px;
  color: #3e3b3bcc;
}
.jSbUIF {
  width: 530px;
  margin-left: 100px;
  flex-shrink: 0;
}
bUCVot {
  width: 1180px;
  margin: -40px auto 0px;
  padding-bottom: 40px;
  position: relative;
  z-index: 1;
}
.cEIUow {
  padding: 14px 10px;
  border-radius: 8px;
  box-shadow: rgb(18 18 18 / 50%) 0px 1px 11px 0px;
  background-color: rgb(37, 37, 37);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 15px;
  position: sticky;
  top: 64px;
  z-index: 2;
}
.eTZcwF {
  display: flex;
}
.eTZcwF a {
  font-size: 18px;
  font-weight: 700;
  color: #3e3b3bcc;
  padding: 0px 10px;
  margin-right: 15px;
  cursor: pointer;
}
.eTZcwF a {
  font-size: 18px;
  font-weight: 700;
  color: #3e3b3bcc;
  padding: 0px 10px;
  margin-right: 15px;
  cursor: pointer;
}
.hZjoUk {
  display: flex;
  padding: 0px 25px;
  margin-right: 20px;
  border-radius: 26px;
  border: 1px solid rgba(76, 76, 76, 0.8);
}
.hZjoUk a {
  font-size: 19px;
 color: #3e3b3bcc;
  padding: 13px 10px;
  margin-right: 15px;
  cursor: pointer;
}
.fWTmyb {
  padding: 30px;
  border-radius: 10px;
  background-color: rgb(33, 33, 34);
  margin-bottom: 25px;
}
.fWTmyb h2 {
  font-size: 28px;
  font-weight: 900;
 color: #3e3b3bcc;
  margin-bottom: 5px;
}
.fWTmyb h3 {
  font-size: 16px;
  color: #3e3b3bcc;
  font-weight: 400;
}
.ioGXEb {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding-top: 25px;
}
.iiaZMj {
  flex: 1 0 0%;
  margin-right: 10px;
}
.fIcWsb {
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 50%) 0px 1px 4px 0px;
  background-color: rgb(41, 41, 41);
  height: 100%;
}
.cVjtzz {
  display: flex;
  border-bottom: 1px solid rgba(151, 151, 151, 0.5);
  min-height: 90px;
}
.cVjtzz li {
  padding: 20px 25px;
  cursor: pointer;
  margin-right: 20px;
  position: relative;
}
.cVjtzz li.selected h5 {
  font-weight: 900;
}
.cVjtzz li.high.selected h6 {
  font-weight: 700;
  color: rgb(51, 209, 143);
}
.cVjtzz li h6 {
  font-size: 16px;
  font-weight: 400;
  color: rgb(155, 155, 155);
  position: relative;
  padding-left: 14px;
}
.cVjtzz li h5 {
  font-size: 22px;
  font-weight: 400;
  color: #3e3b3bcc;
  margin-bottom: 3px;
}
.jtrIDr {
  padding: 25px;
}
.jtrIDr li {
  position: relative;
  padding-left: 17px;
  margin-bottom: 15px;
}
.jtrIDr li > span:first-child {
  font-weight: 900;
  font-size: 17px;
  color: #3e3b3bcc;
  margin-right: 10px;
}
.jtrIDr li > span:last-child {
  font-size: 15px;
  color: #3e3b3bcc;
}
.jtrIDr li:last-child {
  margin-bottom: 0px;
}
.jtrIDr li {
  position: relative;
  padding-left: 17px;
  margin-bottom: 15px;
}
.dleitp {
  width: 400px;
  flex-shrink: 0;
}
.iVFvSv {
  font-size: 54px;
  line-height: 64px;
  color: rgb(10 10 10);
  font-weight: 900;
  margin: 30px auto;
  max-width: 768px;
}
.content_Wrapper {
  max-width: 768px;
  margin: 0 auto;
  padding-top: 60px;
}

.twsxb {
  font-size: 20px;
  line-height: 24px;
  text-align: justify;
  color: #000;
}

.blogTitle {
  font-size: 25px;
  line-height: 30px;
  color: #000;
  text-decoration: underline;
}
.blogTitleNumber {
  font-size: 25px;
  line-height: 30px;
  color: #000;
  margin-bottom: 20px;
}

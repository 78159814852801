@media(max-width:430px) and (max-height:600px){
    .mobileContainer{
        display: flex;
        flex-flow: column;
        margin-top: 20px;
        overflow-y: hidden;
    }

    .mobileContainer .head{
        display: flex;
        flex-grow: row;
       box-shadow: 0 5px 6px grey;
       justify-content: space-around;
    }
    .mobileContainer .inputs{
        margin-top: 40px;
        text-align: center;
    }
    .mobileContainer .inputs input{
        padding: 10px;
        margin: 5px;
        border-radius: 10px;
        box-shadow: 2px 2px 10px grey;
        border: none;
    }

    .mobileContainer .inputs input:focus{
        border: none;
        outline: none;
        box-shadow: 2px 2px 10px lightblue;
    }
    .mobileContainer .inputs .cityName{
        width: 100%;
    }
    .mobileContainer .inputs  .Members{
        width: 100%;
    }

    .mobileContainer .inputs  .checkIn, .mobileContainer .inputs  .checkOut{
        width: 100%;
    }
    .mobileContainer .inputs .Confirm{
        padding: 10px 30px;
        border: none;
        margin-top: 20px;
        border-radius: 15px;
        background-color: lightblue;

    }
    .mobileContainer .inputs .Confirm:hover{
        padding: 10px 30px;
        border: none;
        margin-top: 20px;
        border-radius: 15px;
        background-color: beige;
        
    }

    .mobileContainer .rooms-members-Selector{
        position: fixed;
        display: none;
        transition: all 2s ease-in-out;
        transform: translateY(1500px);
        width: 100%;
        height: 400px;
        z-index: 10;
    }

    .mobileContainer .rooms-members-Selector-visible{
        background-color: white;
        position: fixed;
        display: block;
        transition: all 2s ease-in-out;
        transform: translateY(200px);
        width: 100%;
        height: 500px;
        z-index: 10;
        text-align: center;
        font-size: 1.8rem;
    } 
    .mobileContainer .rooms-members-Selector button,.mobileContainer .rooms-members-Selector-visible button{
        padding: 10px 15px;
        border-radius: 40px;
        font-size: 1.8rem;
        border: none;
        margin-left: 10px;
        margin-right: 10px;
    }
    .mobileContainer .rooms-members-Selector .remove,.mobileContainer .rooms-members-Selector-visible .remove
    {
        padding: 10px 25px !important;
        position: relative;
        right: 0;
        background-color: tomato;
    }   
    /* .mobileContainer .rooms-members-Selector-visible .text{
        font
    } */
}
.hsw .hsw_inputBoxActivity {
    background: rgba(255,255,255, 0.1);
    border-radius: 4px;
    margin-right: 5px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    height: 48px;
}

.activityFilters{
    width: 100%;
    height: 150px;
}
.activitySlider {
        position: relative;
        display: block;
        box-sizing: border-box;
        -webkit-user-select: none;
        user-select: none;
        -webkit-touch-callout: none;
        -khtml-user-select: none;
        touch-action: pan-y;
        -webkit-tap-highlight-color: transparent;
        padding: 5px 5px 5px 5px;
        margin: 0;
        margin-bottom: 0 !important;
        height: 100px !important;
        margin-top: 20px;
        
}

.activitySlider .slick-slide {

    float: left;
    height: 100%;
    min-height: 1px;
    padding: 0px;
    padding-right: 10px;
    height: auto;

}
.Activity-title{
    align-items: center;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: .5rem;
    padding: .5rem 1rem;
    filter: drop-shadow(0 1px 1px rgba(0,0,0,.2));
    display: flex !important;
}
.Activity-title>img{
    width: 50px;
    margin-left: 10px;
}
@media(max-width:430px){
    .Activity-title>img{
        max-height: 820px !important;
        width:100%;
    
    }
    .landingCard .slick-slider{
        height: auto !important;
    }
    .text-top h4{
        font-size: 1.9rem !important;
    }
    .text-top p{
        font-size: 1.8rem !important;
    }
}
.Event_viewAll{
    position: absolute;
    right: 215px;
    margin-top: 19px;
    z-index: 2;
}
.Event_viewAll>a{
    color: #deb956;
    text-transform: uppercase;
    font-size: 17px;
    cursor: pointer;
    font-weight: bold;
}
.activitySlider .slick-list{
    margin-top: -2px !important;
}
.landingCard{
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    margin-bottom: 20px;
    -webkit-box-shadow: 0 3px 30px 0 rgb(0 0 0 / 10%);
    -moz-box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 3px 30px 0 rgb(0 0 0 / 10%);
    background-color: #ffffff;
}

.activitySlider .slick-prev, .slick-next {
    position: absolute !important;
    top: 50% !important;
    fill: #fff;
    height: 40px;
    width: 40px;
    z-index: 5;
    filter: drop-shadow(0 2px 3px rgba(0,0,0,.2));
}

.activitySlider .slick-disabled{
    display: none !important;
}

.activitySlider .slick-prev {
    left: -10px !important;
    border-radius: 50% !important;
}

.activitySlider .slick-next {
    right: -10px !important;
    border-radius: 50% !important;
}

.super-filter-text {
    -webkit-box-flex: 1;
    flex: auto;
    font-size: .9em;
    line-height: 1.2;
    max-width: 9em;
    font-size: 15px;
}
.font-weight-bold {
    font-weight: bold !important;
}
.text-top{
    width: 250px;
    margin: 8px auto;
}
.text-top h4{
    font-size: 18px;
    margin-bottom: 2px;
}

.main-container .text p{
    font-size: larger;
}

.main-container .text{
    text-align: center;
}

.main-container .text hr{
    width: 20%;
    margin: auto;
}

.new-buttons{
    margin-left: auto;
    margin-right: auto;
}

.new-buttons .btn1{
    display: inline-block;
    margin:5px;
    border-radius: 3px;
    color: black;
    border: 1px solid orange;
    padding:8px 15px;
    font-size: large;
    background-color: white;
    transition: all 0.3s ease-in-out;
}

.new-buttons .btn1:hover{
    background-color: rgb(37, 197, 122);
    border: 1px solid rgb(37, 197, 122);
    color: white;
    transition: all 0.3s ease-in-out;
}

.main-container .Event-tiles {
    margin-top: 100px;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
}

.main-container .Event-tiles .Event-tile{
    height: 350px;
    width: 330px;
    border: 1px solid transparent;
    margin-right: 20px;
    margin-bottom: 40px;
    border-radius: 15px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.main-container .Event-tiles .Event-tile img{
    height: 100%;
    width: 100%;
    border-radius: 15px;
    -webkit-transform:scale(1.2);
	-moz-transform:scale(1.2);
	-ms-transform:scale(1.2);
	-o-transform:scale(1.2);
	transform:scale(1.2);
    transition: all 0.3s ease-in-out;
}

.main-container .Event-tiles .Event-tile:hover img{
    -webkit-transform:scale(1);
	-moz-transform:scale(1);
	-ms-transform:scale(1);
	-o-transform:scale(1);
	transform:scale(1);
    transition: all 0.3s ease-in-out;
}


.main-container .Event-tiles .Event-tile .text-top{
    position:relative;
    display: block;
    z-index: 20;
    background-color:#eea02759 !important;
    height: 40%;
    width: 100%;
    bottom: 80px;
    text-align: center;
}

.main-container .Event-tiles .Event-tile .text-top h4,.main-container .Event-tiles .Event-tile .text-top p{
    color: white;
}

@media(max-width:1400px)
{
    .main-container{
        flex-wrap: nowrap;
        margin-bottom: 0;
    }
}

/* @media(min-width:1055px)and(max-width:1400px)
{
    .main-container{
        flex-wrap: nowrap;
        margin-bottom: 900px;
    }
}

@media(max-width:1050px)
{
    .main-container{
        margin-bottom: 1300px;
    }
} */

.image-gallery-swipe {
    width: 640px;
    height: 400px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
#transport_type_id_details{
    background: rgb(118 168 210);
    color: #fff;
    width: 100%;
    height: 30px;
    float: left!important;
    margin: 0px;
    padding: 0px 0px 5px 0px!important;
    box-shadow: none;
    text-shadow: none!important;
    font-weight: 400;
    font-family: 'Arimo', sans-serif;
    border: none;
    outline: 0;
}
.rct-node-icon {
    color: #33c;
    display: none;
}
.rct-title {
    padding: 0 5px;
    position: absolute;
    font-size: 14px;
    color: #555;
    font-weight: 400;
    cursor: pointer;
    text-transform: capitalize;
}
.makeFlexNew{
     margin-top: 15px;
}
.d-flax.justifi-content-center {
    text-align: center;
    border: none;
    border-radius: 10px;
    padding: 4px 40px;
    width: 800px;box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin: 0px auto;
  }
  .soldoutcontent h3{
    margin: 0px;
    padding-bottom: 15px;
    font-size: 22px;
    font-weight: 600;
    color: #0076b7;
  }
  .soldoutcontent p{
    font-size: 18px;
    text-align: center;
    font-weight: 400;
    line-height: 23px;
    padding: 0px 0px 15px 0px;
  }
  .hsw_searchButtonList{
    width: 225px;
    font-size: 18px;
    margin-left: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    font-weight: bold;
    background: #00a6f9;
    color: #fff;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  



*,
html {
  box-sizing: border-box;
}

/* .bgGradient{
    background: #f1f1f1d1;
    background-repeat: no-repeat;
    background-image: linear-gradient( 
        to bottom
         , #000075,#0000FF);
    background-size: 100% 550px;
    min-height: 100vh;
} */

/* .bgGradient{
    background: #f1f1f1d1;
    background-repeat: no-repeat;
    background-image: linear-gradient( 
        to bottom
         , #35A4FB,#012949);
    background-size: 100% 550px;
    min-height: 100vh;
} */

/* .bgGradient{
    background: #f1f1f1d1;
    background-repeat: no-repeat;
    background-image: linear-gradient( 
        to bottom
         , #00008B,#4444FF);
    background-size: 100% 550px;
    min-height: 100vh;
} */
/* .bgGradient{
    background: #f1f1f1d1;
    background-repeat: no-repeat;
    background-image: linear-gradient( 
        to bottom
         , #000080,#0000FF);
    background-size: 100% 550px;
    min-height: 100vh;
} */
/* .bgGradient{
    background: #f1f1f1d1;
    background-repeat: no-repeat;
    background-image: linear-gradient( 
        to bottom
         , #191970,#8A8AE5);
    background-size: 100% 550px;
    min-height: 100vh;
} */
/* .bgGradient{
    background: #f1f1f1d1;
    background-repeat: no-repeat;
    background-image: linear-gradient( 
        to bottom
         , #051322,#0000FF);
    background-size: 100% 550px;
    min-height: 100vh;
} */

/* .bgGradient{
    background: #f1f1f1d1;
    background-repeat: no-repeat;
    background-image: linear-gradient( 
        to bottom
         , #051322,#15457c);
    background-size: 100% 550px;
    min-height: 100vh;
} */

/* .bgGradient{
    background: #f1f1f1d1;
    background-repeat: no-repeat;
    background-image: linear-gradient( 
        to top
         , #8A8AFF,#000075);
    background-size: 100% 550px;
    min-height: 100vh;
} */
/* .bgGradient{
    background: #f1f1f1d1;
    background-repeat: no-repeat;
    background-image: linear-gradient( 
        to bottom
         , #156760,#30D5C8);
    background-size: 100% 550px;
    min-height: 100vh;
} */
/* .bgGradient{
    background: #f1f1f1d1;
    background-repeat: no-repeat;
    background-image: linear-gradient( 
        to bottom
         , #000075,#0000FF,#35A4FB,#012949);
    background-size: 100% 550px;
    min-height: 100vh;
} */
/* .bgGradient{
    background: #f1f1f1d1;
    background-repeat: no-repeat;
    background-image: linear-gradient( 
        to bottom
         , #6B6B6B,#F5F5F5);
    background-size: 100% 550px;
    min-height: 100vh;
} */

/* .bgGradient{
    background: #f1f1f1d1;
    background-repeat: no-repeat;
    background-image: linear-gradient( 
        to bottom
         , #553C10,#C98D26);
    background-size: 100% 550px;
    min-height: 100vh;
} */

/* .bgGradient{
    background: #f1f1f1d1;
    background-repeat: no-repeat;
    background-image: linear-gradient( 
        to bottom
         , #08153A,#0B1F65,#2535D9);
    background-size: 100% 550px;
    min-height: 100vh;
} */

/* .bgGradient{
    background: #f1f1f1d1;
    background-repeat: no-repeat;
    background-image: linear-gradient( 
        to top
         , #08153A,#0B1F65,#2535D9);
    background-size: 100% 550px;
    min-height: 100vh;
} */

.bgGradient {
  background: #f1f1f1d1;
  background-repeat: no-repeat;
  background-image: linear-gradient(
    to bottom,
    #003b73,
    #0074b7,
    #60a3d9,
    #bfd7ed
  );
  background-size: 100% 550px;
  min-height: 100vh;
}

@media (max-width: 1200px) {
  .bgGradient {
   
  }
}
.menu-top ul li{float: left;    cursor: pointer;
  background-color: hsla(0,0%,100%,.15);
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 20%);
  margin-left: 5px;
  border-radius: 4px;
  padding: 5px 10px;}

.logo{padding: 10px 0px 0px 0px;}

.menu-top{padding: 10px 0px 0px 0px;
  display: inline-block;
  clear: both;
  float: right;}

.header-top-section{width: 66%;
  margin: 0px auto;
  position: relative;
  z-index: 9;}

/* .bgGradient{
    background: #f1f1f1d1;
    background-repeat: no-repeat;
    background-image: linear-gradient( 
        to bottom
         , #003060,#055C9D,#0E86D4, #68BBE3);
    background-size: 100% 550px;
    min-height: 100vh;
} */

/* .bgGradient{
    background: transparent;
    background-repeat: no-repeat;
    background-image: url(../assets/images/background-images/shifaaz-shamoon-sLAk1guBG90-unsplash.jpg);
    background-position: 10px;
    min-height: 100vh;
} */

.topSection {
  display: flex;
  position: relative;
}
.appendBottom40 {
  margin-bottom: 40px !important;
}

.landingContainer {
  width: 1200px;
  /* width: 100%; */
  margin: 0 auto;
}

.hsFlex_Bann.hsCenter {
  align-items: center;
}

.hsFlex_Bann.hsCenter {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.hsFlex_Bann.hsCenter {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.prependTop5 {
  padding-top: 5px;
}
.hsFlex_Bann {
  display: flex;
}

.pushRight {
  margin-left: auto;
}

.userSection {
  display: flex;
  height: 40px;
  margin-top: 10px;
}
.hsMyTrips {
  width: 110px;
  height: 35px;
}
.hsSupport {
  width: max-content;
  max-width: 210px;
  height: 35px;
}
.hsMyWallet {
  width: 55px;
  height: 35px;
}
.hsUser {
  width: 213px;
  height: 35px;
}
.hsUser2 {
  width: 150px;
  height: 35px;
}

.userSection li {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.15);
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 20%);
  margin-left: 5px;
  border-radius: 4px;
  padding: 5px 10px;
}
.latoBold {
  font-weight: 700;
}

.font10 {
  font-size: 10px;
  line-height: 10px;
}

.appendRight10 {
  margin-right: 10px;
}

.appendRight10 {
  margin-right: 10px;
}
.myTripsIcon {
  width: 16px;
  height: 25px;
  background-position: -118px -275px;
}
.landingSprite {
  background-image: url(../assets/images/landingSprite.png);
  background-repeat: no-repeat;
  display: inline-block;
  background-size: 200px 600px;
  font-size: 0;
  flex-shrink: 0;
}

.hsFlex_Bann.column {
  flex-direction: column;
}
.hsFlex_Bann .flexOne {
  flex: 1;
}

.appendBottom3_Header {
  margin-bottom: -3px;
  font-family: "Arimo", sans-serif;
  font-weight: 100;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.5px;
}
.hs-white {
  color: #fff;
}
.simpleMap {
  width: 100%;
  height: 100%;
  z-index: 0;
}

.loginIcon {
  width: 20px;
  margin-right: 5px;
}

.appendRight10 {
  margin-right: 10px;
}
.hsFlex_Bann.perfectCenter {
  align-items: center;
  justify-content: center;
}

.sercheng {
  margin: -26px 0px 20px 0px;
  justify-content: center;
  display: flex;
  z-index: 999999;
}
.btn_searchs {
  margin: 0px 0px 0px 0px;
  padding: 5px 50px 5px 50px;
  font-size: 22px;
  border-radius: 29px;
  border: none !important;
  font-weight: 400;
  /* background-image: linear-gradient( 
        276deg
        , #deba56,#040404); */

  /* background-image: linear-gradient(
    -276deg,
    #003b73,
    #0074b7,
    #60a3d9,
    #bfd7ed
  ); */
  background-image: linear-gradient(96deg, #bfd7ed, #53b2fe, #065af3);
  text-decoration: none;
  color: white;
  z-index: 2;
  font-family: "Arimo", sans-serif;
}

a {
  text-decoration: none !important;
}
.btn_searchs:hover {
  color: #fff !important;
}
.myIconWhite {
  background-position: -67px -489px !important;
}

.supportIcon {
  width: 23px;
  height: 25px;
  background-position: -174px -2px;
}
.react-google-flight-datepicker .dialog-date-picker.open {
  animation: showPopup 0.2s forwards !important;
}
.walletBlanceIcon {
  width: 25px;
  height: 25px;
  background-position: -145px -2px;
}

/* header outer */

.headerOuter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.headerOuter {
  position: absolute;
  left: 0;
  right: 0;
  margin-top: -35px;
  z-index: 11;
}

.chHeaderWrapper.stickyHeader {
  background: #fff;
  -webkit-box-shadow: 0 1px 7px 0 rgb(0 0 0 / 40%);
  -moz-box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.4);
  box-shadow: 0 1px 7px 0 rgb(0 0 0 / 40%);
}

.stickyHeader {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  -webkit-animation: slideInDown 0.2s linear;
  -moz-animation: slideInDown 0.2s linear;
  -o-animation: slideInDown 0.2s linear;
  animation: slideInDown 0.2s linear;
  z-index: 14;
}

.navOnly .chHeaderContainer {
  padding: 0;
}

.navOnly .chHeaderContainer {
  width: 100%;
}
.chHeaderContainer {
  width: 1200px;
  margin: 0 auto;
  padding: 10px 0 0;
}

.navOnly .chHeaderContainer > ul,
html[dir="ltr"] .navOnly .chHeaderContainer > .logoContainer {
  display: none;
}

.navOnly .chHeaderContainer > ul,
html[dir="ltr"] .navOnly .chHeaderContainer > .logoContainer {
  display: none;
}

.chHeaderContainer .chMmtLogo {
  width: 113px;
  height: 36px;
  display: inline-block;
}

.navOnly .chHeaderContainer nav {
  width: 100%;
  padding-top: 10px;
}

.navOnly .chHeaderContainer nav {
  width: 100%;
  padding-top: 10px;
}

.chHeaderWrapper.navOnly nav {
  height: 79px;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.navOnly .chHeaderContainer nav {
  width: 100%;
  padding-top: 10px;
}

.navOnly .chHeaderContainer nav {
  width: 100%;
  padding-top: 10px;
}

.chHeaderWrapper.navOnly nav,
.chHeaderWrapper.stickyHeader {
  background: #fff;
  -webkit-box-shadow: 0 1px 7px 0 rgb(0 0 0 / 40%);
  -moz-box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.4);
  box-shadow: 0 1px 7px 0 rgb(0 0 0 / 40%);
}

@media (max-width: 420px) {
}

.chHeaderWrapper.navOnly nav {
  height: 79px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.chHeaderContainer nav {
  flex: 2;
}

.chHeaderContainer nav {
  -webkit-box-flex: 2;
  -webkit-flex: 2;
  -moz-box-flex: 2;
  -ms-flex: 2;
  flex: 2;
}
.chHeaderWrapper.stickyHeader .chHeaderContainer,
.staticHeader .chHeaderContainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}
.navOnly .hsFlex_Bann > .active {
  color: #0074b7;
  background-color: transparent;
  font-family: "Arimo", sans-serif;
}
.stickyHeader .hsFlex_Bann > .active {
  color: #007bff;
  /* font-weight: 600; */
  background-color: transparent;
}

.navOnly .chHeaderContainer nav ul {
  width: 100%;
}

.navOnly .font12 {
  font-size: 12px;
}

.chHeaderContainer nav ul {
  width: 750px !important;
  margin-bottom: 20px;
  cursor: pointer;
}

.hsFlex_Bann {
  display: flex;
}

.font12 {
  font-size: 12px;
  line-height: 12px;
}

.navOnly .chHeaderContainer nav ul li {
  display: flex;
  align-items: flex-end;
  margin-left: 25px;
  padding: 8px;
}

.navOnly .chHeaderContainer nav ul li {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -moz-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.chHeaderContainer nav ul li {
  margin: 0 10px;
  position: relative;
  -webkit-flex-shrink: 0 !important;
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.hsFlex_Bann.column {
  flex-direction: column;
}
.hsFlex_Bann.hsCenter {
  align-items: center;
}

@media (max-width: 426px) {
  /* .navOnly .chHeaderContainer li .chNavIcon {
        width: 100px;
        height: 84px;
    } */
  /* .chHeaderWrapper.navOnly nav {
        height: 129px !important;
        width: auto;
    } */
  .navOnly .chHeaderContainer nav ul {
    width: 100% !important;
  }

  .navOnly .font12 {
    font-size: 12px;
    font-weight: 600;
  }
  .chHeaderWrapper.navOnly nav {
    height: 150px;
  }
}

.navOnly .chHeaderContainer li .chNavIcon {
  width: 40px;
  height: 34px;
}

.navOnly .active .chFlights {
  background-position: -4px -0px;
}

.navOnly .active .chFlights {
  background-position: -4px 0;
}

.chHeaderContainer li .chNavIcon {
  width: 40px;
  height: 34px;
}

.navOnly .chFlights {
  background-position: -4px -38px;
}

/* .navOnly .chSprite {
  background-size: 300px 267px;
} */
/* .navOnly .chHotels {
  background-position: -50px -37px;
}

.navOnly .chHomestays {
  background-position: -210px -77px;
}
.navOnly .chHolidays {
  background-position: -192px -157px;
}
.navOnly .chTrains {
  background-position: -114px -38px;
} */
/* .navOnly .chBuses {
  background-position: -66px -13px;
} */

/* .navOnly .chHomestays {
  background-position: -168px -74px;
} */
/* .navOnly .chHotels {
  background-position: -50px 0px !important;
} */
/* .navOnly .chHolidays {
  background-position: -192px -195px;
} */
/* .navOnly .chTrains {
  background-position: -114px 0px;
} */
/* .navOnly .chHomestays {
  background-position: -210px -77px;
} */

.chHotels {
  background-image: url(../assets/images/icon/hotels.jpeg) !important;
  background-repeat: no-repeat;
  -webkit-background-size: 45px 39px;
  -moz-background-size: 45px 39px;
  background-size: 45px 39px;
}
.chHomestays {
  background-image: url(../assets/images/icon/attractions.jpeg) !important;
  background-repeat: no-repeat;
  -webkit-background-size: 45px 39px;
  -moz-background-size: 45px 39px;
  background-size: 45px 39px;
}
.chHolidays {
  background-image: url(../assets/images/icon/packages.jpeg) !important;
  background-repeat: no-repeat;
  -webkit-background-size: 45px 39px;
  -moz-background-size: 45px 39px;
  background-size: 45px 39px;
}
.chTrains {
  background-image: url(../assets/images/icon/transfer.jpeg) !important;
  background-repeat: no-repeat;
  -webkit-background-size: 45px 39px;
  -moz-background-size: 45px 39px;
  background-size: 45px 39px;
}
.chBuses {
  background-image: url(../assets/images/icon/landandferry.jpeg) !important;
  background-repeat: no-repeat;
  -webkit-background-size: 45px 39px;
  -moz-background-size: 45px 39px;
  background-size: 45px 39px;
}


/* .chBuses {
  background-image: url(../assets/images/istockphoto.jpg) !important;
  background-repeat: no-repeat;
  -webkit-background-size: 230px 200px;
  -moz-background-size: 230px 200px;
  background-size: 230px 200px;
} */

/* .stickyHeader .chFlights {
  background-position: -4px -38px;
}

.stickyHeader .chSprite {
  background-size: 300px 267px;
}
.stickyHeader .chHotels {
  background-position: -50px -37px;
}

.stickyHeader .chHomestays {
  background-position: -210px -77px;
}
.stickyHeader .chHolidays {
  background-position: -192px -157px;
}
.stickyHeader .chTrains {
  background-position: -114px -38px;
}
.stickyHeader .chBuses {
  background-position: -66px -13px;
} */
.appendBottom2 {
  margin-bottom: 2px;
}

.chFlights {
  background-position: -2px -30px;
}
.appendBottom2 {
  margin-bottom: 2px;
}

.chFlights {
  background-position: -2px -30px;
}

.appendBottom2 {
  margin-bottom: 2px;
}

.chFlights {
  background-position: -2px -30px;
}

/* .chSprite {
  background-image: url(../assets/images/commonHeader.png) !important;
  background-repeat: no-repeat;
  -webkit-background-size: 230px 200px;
  -moz-background-size: 230px 200px;
  background-size: 230px 200px;
} */

.darkGreyText {
  color: #4a4a4a;
  font-family: "Arimo", sans-serif;
}

/* .chHeaderContainer .chMmtLogo img {
    width: 100%;
} */

.chHeaderContainer nav {
  flex: 2;
}
.chHeaderContainer nav {
  -webkit-box-flex: 2;
  -webkit-flex: 2;
  -moz-box-flex: 2;
  -ms-flex: 2;
  flex: 2;
}

.navOnly .chHeaderContainer > ul,
.navOnly .chHeaderContainer > .logoContainer {
  display: none;
}
.navOnly .chHeaderContainer > ul,
.navOnly .chHeaderContainer > .logoContainer {
  display: none;
}
.chHeaderContainer .logoContainer {
  font-size: 14px;
  margin-right: 50px;
  min-width: 120px;
  display: flex;
}
.hs-LoginFlex.perfectCenter {
  background-image: -webkit-linear-gradient(45deg,#219393,#ace143)!important;
  margin-top: 2px;
}
.serch-top-bar {
  margin: 0 auto;
  position: relative;
  border-radius: 8px;
  box-shadow: 0 1px 5px 0 rgb(0 0 0 / 10%);
  background-color: #fff;
  padding: 50px 20px 50px;
  clear: both;
  display: inline-block;
  width: 100%;
  max-width: 100%;
}
.bor:hover {    background-color: #4093ce5e;}


.bor{border: 1px solid #c1c1c1b5;
  padding: 10px;
  display: inline-block;
  width: 100%;height: 85px;
  border-radius: 5px;    cursor: pointer;}
.mainContainer {
  margin-top: -3% !important;
}

@media (max-width: 426px) {
  .mainContainer {
    display: none;
  }
}

.widgetSection {
  width: 1200px;
  min-height: 230px;
  margin: 0 auto;
  position: relative;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-shadow: 0 1px 5px 0 rgb(0 0 0 / 10%);
  -moz-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px 0 rgb(0 0 0 / 10%);
  background-color: #fff;
  padding: 50px 20px 0;
}

#mobileSearchBtn {
  display: none;
  position: relative;
}
@media (max-width: 426px) {
  #mobileSearchBtn {
    display: block;
   
    background-color: lightblue;
    border-radius: 25px;
    border: none;
    
    font-size: 1.8rem;
    margin-bottom: 200px;
  }
}

.error {
  color: red;
  font-size: 10px;
  text-align: left;
}

.containerLogin {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  width: 768px;
  max-width: 100%;
  min-height: 500px;
}

.form-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.sign-in-container {
  left: 0;
  width: 50%;
  z-index: 2;
}

.containerLogin.right-panel-active .sign-in-container {
  transform: translateX(100%);
}

.sign-up-container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}

.containerLogin.right-panel-active .sign-up-container {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: show 0.6s;
}

@keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.containerLogin.right-panel-active .overlay-container {
  transform: translateX(-100%);
}

.overlay {
  background: linear-gradient(261deg, #53b2fe, #0074b7);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.containerLogin.right-panel-active .overlay {
  transform: translateX(50%);
}

.overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.overlay-left {
  transform: translateX(-20%);
}

.containerLogin.right-panel-active .overlay-left {
  transform: translateX(0);
}

.overlay-right {
  right: 0;
  transform: translateX(0);
}

.containerLogin.right-panel-active .overlay-right {
  transform: translateX(20%);
}

.social-container {
  margin: 20px 0;
}

.social-container a {
  border: 1px solid #dddddd;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  height: 40px;
  width: 40px;
}

.ReactModal__Overlay {
  position: fixed;
  inset: 0px;
  /* background-color: rgba(255, 255, 255, 0.75); */
  background: rgb(4 4 4 / 50%) !important;
}

.ReactModal__Content {
  position: absolute;
  inset: 40px;
  border: none !important;
  background: transparent !important;
  overflow: hidden !important;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  width: 800px;
  margin: 0 auto;
}

.FormCon {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
}

.FormCon h1 {
  color: #000;
  font-weight: 800;
  font-size: 30px;
  font-family: "Montserrat", sans-serif;
}

.overlay h1 {
  color: #fff;
  font-weight: 800;
  font-size: 30px;
  font-family: "Montserrat", sans-serif;
}
.overlay p {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}

.FormCon input {
  background-color: #fff;
  border: solid 1px #bebebe;
  padding: 8px 15px;
  margin: 8px 0;
  width: 100%;
  outline: 0;
}

.FormCon button {
  border-radius: 5px;
  border: 1px solid #0074b7;
  background-color: #0074b7;
  color: #ffffff;
  font-size: 12px;
  width: 100%;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  outline: 0;
}

.overlay button {
  border-radius: 5px;
  color: #ffffff;
  font-size: 12px;
  width: 100%;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  outline: 0;
}
.ghost {
  background-color: transparent;
  border: 1px solid #ffffff;
}
.FormCon a {
  align-self: flex-start;
  margin-bottom: 10px;
  color: #000;
}
.FormConp {
  align-self: flex-start;
  margin-bottom: 10px;
  margin-top: 10px;
  color: #000;
}
.SocialmakeFlex.vrtlCenter {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.SocialmakeFlex {
  display: flex;
}

.SocialmakeFlex .flexOne {
  flex: 1;
}

.socialBtnContainer {
  width: 100%;
  margin-top: 15px;
}

.popupGoogleIcon,
.popupFbIcon {
  width: 20px;
  height: 20px;
  margin: 12px 12px 12px 20px;
}
.popupSprite {
  background: url(https://imgak.mmtcdn.com/pwa_v3/pwa_hotel_assets/header/popupFlowSprite.png)
    no-repeat;
  -webkit-background-size: 170px 170px;
  -moz-background-size: 170px 170px;
  background-size: 170px 170px;
}

.popupGoogleIcon {
  background-position: -55px -35px;
  -webkit-background-size: 150px 160px;
  -moz-background-size: 150px 160px;
  background-size: 150px 160px;
}
.popupFbIcon {
  background-position: -30px -31px;
  -webkit-background-size: 145px 135px;
  -moz-background-size: 145px 135px;
  background-size: 145px 135px;
}

.fbLoginBtn {
  background-color: #3b589a;
}

.fbLoginBtn,
.googleLoginBtn {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: none;
  -webkit-box-shadow: 0 1px 4px 0 rgb(0 0 0 / 30%);
  -moz-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 30%);
}

.userSection li.hsUser.userLoggedIn {
  background-image: none;
  position: relative;
}

.userSection li.hsUser.userLoggedIn .userNameIcon {
  background-image: -webkit-linear-gradient(45deg, #219393, #ace143);
  background-image: -moz-linear-gradient(45deg, #219393, #ace143);
  background-image: -o-linear-gradient(45deg, #219393, #ace143);
  background-image: linear-gradient(45deg, #219393, #ace143);
}

.hs-LoginFlex.perfectCenter {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.userNameIcon {
  width: 20px;
  height: 20px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

.userNameImage {
  width: 25px;
  height: 25px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.hs-LoginFlex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

.hs-LoginFlex.column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.truncate {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: inline-block;
}

.appendBottom3 {
  margin-bottom: 3px;
}

.userDropdown {
  width: 348px;
  -webkit-animation: fadeInDown 0.3s linear;
  -moz-animation: fadeInDown 0.3s linear;
  -o-animation: fadeInDown 0.3s linear;
  animation: fadeInDown 0.3s linear;
  background: #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  position: absolute;
  top: 40px;
  right: 0;
  padding: 10px 0px 5px 0px;
  z-index: 14;
  -webkit-box-shadow: 0 1px 10px 0 rgb(0 0 0 / 20%);
  -moz-box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 10px 0 rgb(0 0 0 / 20%);
}

.paddingLR20 {
  padding-right: 20px;
}

.customerName {
  font-size: 11px;
  line-height: 15px;
  /* font-weight: 400; */
  margin-top: 3px;
  text-transform: capitalize;
}

.userItems {
  padding: 10px 20px;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.userItems:hover {
  background-color: #26b5a929;
}
.myWalletTag {
  background: #004883;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  padding: 2px 8px;
}

.landingSprite {
  background-image: url(https://imgak.mmtcdn.com/pwa_v3/pwa_commons_assets/desktop/landingSprite@10x.png);
  background-repeat: no-repeat;
  display: inline-block;
  -webkit-background-size: 200px 600px;
  -moz-background-size: 200px 600px;
  background-size: 200px 600px;
  font-size: 0px;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.travel_blog {
  width: 25px;
  height: 25px;
}
.userItemMyWallet {
  width: 24px;
  height: 18px;
  background-position: -143px -166px;
}

.userItemMyTrips {
  width: 24px;
  height: 22px;
  background-position: -111px -166px;
}

.userItemMyProfile {
  width: 21px;
  height: 21px;
  background-position: -112px -197px;
}
.appendRight20 {
  margin-right: 20px;
}
.userItemMakePayment {
  width: 19px;
  height: 24px;
  background-position: -175px -194px;
}

.userItemLogout {
  width: 18px;
  height: 18px;
  background-position: -182px -127px;
}

.dropbanner {
  top: 35px;
}
.react-google-flight-datepicker .dialog-header {
  padding: 0px 0px 0px 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Arimo", sans-serif !important;
}

.react-google-flight-datepicker .selected-date {
  display: flex;
  flex: 1 1;
  align-items: center;
  height: 100%;
  font-size: 14px !important;
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "Arimo", sans-serif !important;
}
.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.7rem;
  outline: 0;
}

.errornews {
  color: red;
  text-align: left;
}

.succesnews {
  color: red;
  text-align: left;
}

#newsletter input[type="submit"] {
  position: absolute;
  right: 0;
  color: #fff;
  font-size: 13px;
  font-size: 0.8125rem;
  font-weight: 600;
  top: 0;
  border: 0;
  padding: 0 12px;
  height: 40px;
  cursor: pointer;
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  -ms-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
  background-color: #0071c2;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  outline: none;
}


@media screen and (max-width: 600px) {
  

  .menu-top ul li {
    float: left;
    cursor: pointer;
    background-color: hsla(0,0%,100%,.15);
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 20%);
    margin-left: 5px;
    border-radius: 4px;
    padding: 5px 10px;
    width: 47%;
    margin-bottom: 15px;
}
.full-with{width:96% !important;}
.logo {
  padding: 10px 0px 0px 0px;
  text-align: center;
  margin-bottom: 10px;
}
.header-top-section {
  width: 100%;
  margin: 0px auto;
  position: relative;
  z-index: 9;
}
.chHeaderWrapper.navOnly nav {
  height: auto !important;
  border-radius: 8px;
  display: flex;
  align-items: center;
}
.chHeaderContainer nav ul li {
  margin: 0 10px;
  position: relative;
  flex-shrink: 0 !important;
  display: block !important;
  float: left;
}
.chHeaderContainer nav ul {
  margin-bottom: 10px;
  cursor: pointer;
  display: block;
}
.mobile-view{      margin: 0px !important;}

.Guestss p {
  font-size: 15px !important;
  color: black;
  font-weight: 600;
  text-shadow: none !important;
  font-family: 'Arimo', sans-serif;
}.Guestss p {
  font-size: 15px !important;
  color: black;
  font-weight: 600;
  text-shadow: none !important;
  font-family: 'Arimo', sans-serif;
}
.panel-dropdown {
  position: relative;
  text-align: center;
  padding: 0px 0px 0 0px;
  font-size: 16px;
  font-weight: 700;
}
.form-group span {
  margin: 0px;
  padding: 10px;
  float: left;
  color: black;
  text-align: center !important;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  font-family: 'Arimo', sans-serif;
}
.react-google-flight-datepicker .selected-date {
  display: flex;
  flex: 1 1;
  align-items: center;
  height: 100%;
  font-size: 14px !important;
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "Arimo", sans-serif !important;
}.totalsercheng .Allhover {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    padding: 0px !important;
}
.totalsercheng .Allhover {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  padding: 0px !important;
}
}

@media only screen and (max-device-width: 480px) {

  .menu-top ul li {
    float: left;
    cursor: pointer;
    background-color: hsla(0,0%,100%,.15);
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 20%);
    margin-left: 5px;
    border-radius: 4px;
    padding: 5px 10px;
    width: 47%;
    margin-bottom: 15px;
}
.full-with{width:96% !important;}
.logo {
  padding: 10px 0px 0px 0px;
  text-align: center;
  margin-bottom: 10px;
}
.header-top-section {
  width: 100%;
  margin: 0px auto;
  position: relative;
  z-index: 9;
}
.chHeaderWrapper.navOnly nav {
  height: auto !important;
  border-radius: 8px;
  display: flex;
  align-items: center;
}
.chHeaderContainer nav ul li {
  margin: 0 10px;
  position: relative;
  flex-shrink: 0 !important;
  display: block !important;
  float: left;
}
.chHeaderContainer nav ul {
  margin-bottom: 10px;
  cursor: pointer;
  display: block;
}


}
.mobile-menu{display: none;}
@media only screen and (min-width:991px) and (max-width:1199px){
  .mobile-menu{display: none;}
  .header-top-section {
    width: 100%;
    margin: 0px auto;
    position: relative;
    z-index: 9;
}
.navOnly .chHeaderContainer nav ul li {
  display: flex;
  align-items: flex-end;
  margin-left: 16px;
  padding: 8px;
}
.dialog-date-picker{width: 400px !important;
  left: 40px !important;
  height: 445px !important;
  top: 90% !important;}
.defaultZone {
  font-size: 16px !important;
  color: black;
  font-weight: 700 !important;
  text-shadow: none !important;
}
.react-google-flight-datepicker .selected-date {
  display: flex;
  flex: 1 1;
  align-items: center;
  height: 100%;
  font-size: 14px !important;
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "Arimo", sans-serif !important;
}
.Guestss span {
  font-size: 14px;
  font-weight: 400;
  font-family: 'Arimo', sans-serif;
}
#transport_type_id {
  width: 87%;
  height: 29px;
  color: black;
  float: left!important;
  margin: 0px;
  padding: 3px 0px 6px 4px!important;
  box-shadow: none;
  text-shadow: none!important;
  font-weight: 400;
  font-family: 'Arimo', sans-serif;
  font-size: 15px;
  border-radius: 5px;
  border: none;
  outline: 0;
}
.form-select option:first-child {
  background: #ccc;
}
.Allhover{padding: 1px;}
.font18 {
  font-size: 15px;
  line-height: 18px;
}
.stickyHeader {
  background: #fff;
  box-shadow: 0 1px 7px 0 rgb(0 0 0 / 40%);
  display: none;
}
.Event-tile .text-topEvent {
  padding: 10px;
  height: 156px;
}
.EventlandingCard {
  border-radius: 10px;
  box-shadow: 0 3px 30px 0 rgb(0 0 0 / 10%);
  background-color: #ffffff;
  padding: 25px 25px 25px;
  height: 560px;
}
.roomsGuests {
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 6px 0 rgb(0 0 0 / 20%);
  position: absolute;
  right: 0px !important;
  top: 35px;
  width: 497px;
  z-index: 10;
}


}

@media only screen and (min-width:768px) and (max-width: 990px){

  .mobile-menu{display: none;}
  .header-top-section {
    width: 100%;
    margin: 0px auto;
    position: relative;
    z-index: 9;
}

.roomsGuests {
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 6px 0 rgb(0 0 0 / 20%);
  position: absolute;
  right: 0px !important;
  top: 35px;
  width: 417px;
  z-index: 10;
}
.addRoomRowhs .guestCounter {
  display: inline-flex;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 6px 0 rgb(0 0 0 / 20%);
  margin-bottom: 24px;
  align-items: center;
  width:350px;
}
.btnAddRoom {
  width: 200px;
  height: 32px;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 20%);
  background-color: #ffffff;
  border: solid 1px #008cff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 700;
  font-size: 11px;
  color: #008cff;
  outline: 0;
}
.react-google-flight-datepicker .dialog-date-picker.hide {
  animation: hidePopup 0.2s forwards !important;
}
.dialog-date-picker{width: 400px !important;
  left: 40px !important;
  height: 435px !important;
  top: 20% !important;}
.navOnly .chHeaderContainer nav ul li {
  display: flex;
  align-items: flex-end;
  margin-left: 16px;
  padding: 8px;
}
.defaultZone {
  font-size: 16px !important;
  color: black;
  font-weight: 700 !important;
  text-shadow: none !important;
}
.react-google-flight-datepicker .selected-date {
  display: flex;
  flex: 1 1;
  align-items: center;
  height: 100%;
  font-size: 14px !important;
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "Arimo", sans-serif !important;
}
.Guestss span {
  font-size: 14px;
  font-weight: 400;
  font-family: 'Arimo', sans-serif;
}
#transport_type_id {
  width: 87%;
  height: 29px;
  color: black;
  float: left!important;
  margin: 0px;
  padding: 3px 0px 6px 4px!important;
  box-shadow: none;
  text-shadow: none!important;
  font-weight: 400;
  font-family: 'Arimo', sans-serif;
  font-size: 15px;
  border-radius: 5px;
  border: none;
  outline: 0;
}
.Allhover{padding: 1px;}
.font18 {
  font-size: 15px;
  line-height: 18px;
}
.stickyHeader {
  background: #fff;
  box-shadow: 0 1px 7px 0 rgb(0 0 0 / 40%);
  display: none;
}
.react-google-flight-datepicker .date {
  border: 1px solid #dadce0;
  border-radius: 5px;
  padding: 0 10px;
  flex-grow: 1;
  border: none;
}
.font18 {
  font-size: 15px !important;
  line-height: 17px !important;
}
.Event-tile .text-topEvent {
  padding: 10px;
  height: 156px;
}
.EventlandingCard {
  border-radius: 10px;
  box-shadow: 0 3px 30px 0 rgb(0 0 0 / 10%);
  background-color: #ffffff;
  padding: 25px 25px 25px;
  height: 560px;
}
footer h5 {
  color: #040404;
  margin: 25px 0;
  font-size: 15px !important;
  font-weight: 700!important;
}
.roomsGuests {
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 6px 0 rgb(0 0 0 / 20%);
  position: absolute;
  left: -355px !important;
  top: 35px;
  width: 502px;
  z-index: 10;
}
footer ul li a {
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: inline-block;
  position: relative;
  color: #000000;
  font-size: 13px;
  line-height: 20px;
}

.roomsGuests {
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 6px 0 rgb(0 0 0 / 20%);
  position: absolute;
  right: 0px !important;
  top: 35px;
  width: 417px;
  z-index: 10;
}
.addRoomRowhs .guestCounter {
  display: inline-flex;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 6px 0 rgb(0 0 0 / 20%);
  margin-bottom: 24px;
  align-items: center;
  width:350px;
}
.btnAddRoom {
  width: 200px;
  height: 32px;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 20%);
  background-color: #ffffff;
  border: solid 1px #008cff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 700;
  font-size: 11px;
  color: #008cff;
  outline: 0;
}
.react-google-flight-datepicker .change-date-group, .react-google-flight-datepicker .divider, .react-google-flight-datepicker .calendar-flippers, .react-google-flight-datepicker .weekdays {
  display: flex;
}
.react-google-flight-datepicker .flipper-button {
  background-color: #fff;
  border-radius: 100%;
  box-shadow: 0 0 4px rgb(0 0 0 / 12%), 0 4px 4px rgb(0 0 0 / 24%);
  cursor: pointer;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0px;
  pointer-events: all;
}

}

@media only screen and (min-width:480px) and (max-width: 767px){
  .header-top-section{display: block !important;}
  .mobile-menu{display: none;}
  .header-top-section {
    width: 100%;
    margin: 0px auto;
    position: relative;
    z-index: 9;
}
.react-google-flight-datepicker .dialog-header {
  padding: 0px 0px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Arimo", sans-serif !important;
}
.react-google-flight-datepicker .change-date-group, .react-google-flight-datepicker .divider, .react-google-flight-datepicker .calendar-flippers, .react-google-flight-datepicker .weekdays {
  display: flex;
}

.react-google-flight-datepicker .dialog-date-picker.hide {
  animation: hidePopup 0.2s forwards !important;
}
.dialog-date-picker{width: 350px !important;
  left: 40px !important;
  height: 435px !important;
  top: 30% !important;}
.roomsGuests {
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 6px 0 rgb(0 0 0 / 20%);
  position: absolute;
  left: -355px !important;
  top: 35px;
  width: 502px;
  z-index: 10;
}
.navOnly .chHeaderContainer nav ul li {
  display: flex;
  align-items: flex-end;
  margin-left: 16px;
  padding: 8px;
}
.defaultZone {
  font-size: 16px !important;
  color: black;
  font-weight: 700 !important;
  text-shadow: none !important;
}
.react-google-flight-datepicker .selected-date {
  display: flex;
  flex: 1 1;
  align-items: center;
  height: 100%;
  font-size: 14px !important;
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "Arimo", sans-serif !important;
}
.Guestss span {
  font-size: 14px;
  font-weight: 400;
  font-family: 'Arimo', sans-serif;
}
#transport_type_id {
  width: 87%;
  height: 29px;
  color: black;
  float: left!important;
  margin: 0px;
  padding: 3px 0px 6px 4px!important;
  box-shadow: none;
  text-shadow: none!important;
  font-weight: 400;
  font-family: 'Arimo', sans-serif;
  font-size: 15px;
  border-radius: 5px;
  border: none;
  outline: 0;
}
.Allhover{margin-bottom: 10px;}
.font18 {
  font-size: 15px;
  line-height: 18px;
}
.stickyHeader {
  background: #fff;
  box-shadow: 0 1px 7px 0 rgb(0 0 0 / 40%);
  display: none;
}
.react-google-flight-datepicker .date {
  border: 1px solid #dadce0;
  border-radius: 5px;
  padding: 0 10px;
  flex-grow: 1;
  border: none;
}
.font18 {
  font-size: 15px !important;
  line-height: 17px !important;
}
.Event-tile .text-topEvent {
  padding: 10px;
  height: 70px;
}
.EventlandingCard {
  border-radius: 10px;
  box-shadow: 0 3px 30px 0 rgb(0 0 0 / 10%);
  background-color: #ffffff;
  padding: 25px 25px 25px;
  height: 470px;
}
footer h5 {
  color: #040404;
  margin: 25px 0;
  font-size: 18px !important;
  font-weight: 700!important;
}
footer ul li a {
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: inline-block;
  position: relative;
  color: #000000;
  font-size: 15px;
  line-height: 20px;
}
.roomsGuests {
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 6px 0 rgb(0 0 0 / 20%);
  position: absolute;
  left: -3px !important;
  top: 35px;
  width: 417px;
  z-index: 10;
}
.addRoomRowhs .guestCounter {
  display: inline-flex;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 6px 0 rgb(0 0 0 / 20%);
  margin-bottom: 24px;
  align-items: center;
  width:350px;
}
.btnAddRoom {
  width: 200px;
  height: 32px;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 20%);
  background-color: #ffffff;
  border: solid 1px #008cff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 700;
  font-size: 11px;
  color: #008cff;
  outline: 0;
}
.react-google-flight-datepicker .flipper-button {
  background-color: #fff;
  border-radius: 100%;
  box-shadow: 0 0 4px rgb(0 0 0 / 12%), 0 4px 4px rgb(0 0 0 / 24%);
  cursor: pointer;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0px;
  pointer-events: all;
}
.activityDate {
  padding: 10px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 6px 0 rgb(0 0 0 / 20%);
  position: absolute;
  z-index: 5;
  top: 32px;
  left: 0;
  max-width: 100%;
}
.itemThumb {
  max-width: 100% !important;
  margin: 12px 0px!important;
}


}

@media only screen and (max-width: 479px){
  .bor {
    border: 1px solid #c1c1c1b5;
    padding: 10px;
    display: inline-block;
    width: 100%;
    height: 80px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 15px;
}
.react-google-flight-datepicker .change-date-group, .react-google-flight-datepicker .divider, .react-google-flight-datepicker .calendar-flippers, .react-google-flight-datepicker .weekdays {
  display: flex;
}
.react-google-flight-datepicker .flipper-button {
  background-color: #fff;
  border-radius: 100%;
  box-shadow: 0 0 4px rgb(0 0 0 / 12%), 0 4px 4px rgb(0 0 0 / 24%);
  cursor: pointer;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0px;
  pointer-events: all;
}
.react-google-flight-datepicker .dialog-date-picker.hide {
  animation: hidePopup 0.2s forwards !important;
}
.roomsGuests {
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 6px 0 rgb(0 0 0 / 20%);
  position: absolute;
  left: -27px !important;
  top: 35px;
  width: 290px;
  z-index: 10;
}
.addRoomRowhs .guestCounter {
  display: inline-flex;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 6px 0 rgb(0 0 0 / 20%);
  margin-bottom: 24px;
  align-items: center;
  width: 277px;
}
.btnAddRoom {
  width: 200px;
  height: 32px;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 20%);
  background-color: #ffffff;
  border: solid 1px #008cff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 700;
  font-size: 11px;
  color: #008cff;
  outline: 0;
}
.roomsGuestsTop {
  /* padding: 20px 2px; */
  padding: 15px 20px;
  max-height: 60vh;
  overflow-y: auto;
}
.autocomplete-wrapper {
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 6px 0 rgb(0 0 0 / 20%);
  width: 289px !important;
  position: absolute;
  left: 0;
  top: 25px;
  min-height: 316px;
  overflow: hidden;
  z-index: 999999;
}
.react-google-flight-datepicker .dialog-header {
  padding: 0px 0px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Arimo", sans-serif !important;
}
.dialog-date-picker{    width: 288px !important;
  left: 16px !important;
  height: 399px !important;
  top: 16% !important;}
.react-google-flight-datepicker .date {
  border: none;
  border-radius: 5px;
  padding: 0 10px;
  flex-grow: 1;
}
#mobileSearchBtn {
  display: block;
  background-color: lightblue;
  border-radius: 25px;
  border: none;
  font-size: 1.8rem;
  margin-bottom: 200px;
  display: none !important;
}
.mainContainer {
  display: block;
}
.form-group span {
  margin: 0px;
  padding: 0px !important;
  float: left;
  color: black;
  font-size: 14px;
  text-align: left !important;
  text-transform: uppercase;
  font-weight: 700;
  font-family: 'Arimo', sans-serif;
}
.header-top-section{display: block !important;}
  .mobile-menu{display: none;}
  .header-top-section {
    width: 100%;
    margin: 0px auto;
    position: relative;
    z-index: 9;
}
.navOnly .chHeaderContainer nav ul li {
  display: flex;
  align-items: flex-end;
  margin-left: 16px;
  padding: 8px;
}
.defaultZone {
  font-size: 16px !important;
  color: black;
  font-weight: 700 !important;
  text-shadow: none !important;
}
.react-google-flight-datepicker .selected-date {
  display: flex;
  flex: 1 1;
  align-items: center;
  height: 100%;
  font-size: 14px !important;
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "Arimo", sans-serif !important;
}
.Guestss span {
  font-size: 14px;
  font-weight: 400;
  font-family: 'Arimo', sans-serif;
}
#transport_type_id {
  width: 87%;
  height: 29px;
  color: black;
  float: left!important;
  margin: 0px;
  padding: 3px 0px 6px 4px!important;
  box-shadow: none;
  text-shadow: none!important;
  font-weight: 400;
  font-family: 'Arimo', sans-serif;
  font-size: 15px;
  border-radius: 5px;
  border: none;
  outline: 0;
}
.Allhover{margin-bottom: 10px;}
.font18 {
  font-size: 15px;
  line-height: 18px;
}
.stickyHeader {
  background: #fff;
  box-shadow: 0 1px 7px 0 rgb(0 0 0 / 40%);
  display: none;
}
.react-google-flight-datepicker .date {
  border: 1px solid #dadce0;
  border-radius: 5px;
  padding: 0 10px;
  flex-grow: 1;
  border: none;
}
.font18 {
  font-size: 15px !important;
  line-height: 17px !important;
}
.Event-tile .text-topEvent {
  padding: 10px;
  height: 70px;
}
.EventlandingCard {
  border-radius: 10px;
  box-shadow: 0 3px 30px 0 rgb(0 0 0 / 10%);
  background-color: #ffffff;
  padding: 25px 25px 25px;
  height: 470px;
}
footer h5 {
  color: #040404;
  margin: 25px 0;
  font-size: 18px !important;
  font-weight: 700!important;
}
footer ul li a {
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: inline-block;
  position: relative;
  color: #000000;
  font-size: 15px;
  line-height: 20px;
}
.slick-next {
  right: 27px !important;
}
.activityDate {
  padding: 10px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 6px 0 rgb(0 0 0 / 20%);
  position: absolute;
  z-index: 5;
  top: 32px;
  left: 0;
  max-width: 100%;
}

}
.fa.fa-twitter{
	font-family:sans-serif;
}
.fa.fa-twitter::before{
	content:"𝕏";
	font-size:1.1em;
}


.main-container {
  display: flex;
  flex-flow: column;
  height: 100vh;
  margin-top: 50px;
  margin-bottom: 20px;
}
@media (max-width: 420px) {
  /* .container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl{
        width: 40% ;
    }
    .Event_viewAll{
        position: absolute;
        right: 0;
        margin-top: 19px;
        z-index: 2;
    } */
}
.Event-tile {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 12px;
  -webkit-box-shadow: 0 1px 6px 0 rgb(0 0 0 / 20%);
  -moz-box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 6px 0 rgb(0 0 0 / 20%);
  background-color: #ffffff;
  margin: 5px 10px 5px 2px;
  cursor: pointer;
}
.Event-tile > img {
  max-height: 280px;
  border-radius: 12px;
  width: 100%;
  filter: brightness(95%);
}
@media (max-width: 430px) {
  .Event-tile > img {
    max-height: 820px !important;
    width: 100%;
  }
  .landingCard .slick-slider {
    height: auto !important;
  }
  .text-topEvent h4 {
    font-size: 1.9rem !important;
  }
  .text-topEvent p {
    font-size: 1.8rem !important;
  }
}
.Event_viewAll {
  position: absolute;
  right: 215px;
  margin-top: 19px;
  z-index: 2;
}
.Event_viewAll > a {
  color: #deb956;
  text-transform: uppercase;
  font-size: 17px;
  cursor: pointer;
  font-weight: bold;
}
.landingCard {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
  -webkit-box-shadow: 0 3px 30px 0 rgb(0 0 0 / 10%);
  -moz-box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 30px 0 rgb(0 0 0 / 10%);
  background-color: #ffffff;
}

.landingCard .slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  padding: 25px 40px 40px;
  margin: 0 auto;
  height: 420px;
}

.Event-tile .text-topEvent {
    padding: 10px;
    height: 115px;

}
.Event-tile .text-topEvent h4 {
  font-size: 16px;
  margin-bottom: 2px;
  color: #000;
  font-family: 'Arimo', sans-serif;
}
.Event-tile .text-topEvent p {
  font-size: 13px;
  margin-bottom: 2px;
  color: #000;
  font-family: 'Arimo', sans-serif;
}

.main-container .text p {
  font-size: larger;
}

.main-container .text {
  text-align: center;
}

.main-container .text hr {
  width: 20%;
  margin: auto;
}

.new-buttons {
  margin-left: auto;
  margin-right: auto;
}

.new-buttons .btn1 {
  display: inline-block;
  margin: 5px;
  border-radius: 3px;
  color: black;
  border: 1px solid orange;
  padding: 8px 15px;
  font-size: large;
  background-color: white;
  transition: all 0.3s ease-in-out;
}

.new-buttons .btn1:hover {
  background-color: rgb(37, 197, 122);
  border: 1px solid rgb(37, 197, 122);
  color: white;
  transition: all 0.3s ease-in-out;
}

.main-container .Event-tiles {
  margin-top: 100px;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
}

.main-container .Event-tiles .Event-tile {
  height: 350px;
  width: 330px;
  border: 1px solid transparent;
  margin-right: 20px;
  margin-bottom: 40px;
  border-radius: 15px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.main-container .Event-tiles .Event-tile img {
  height: 100%;
  width: 100%;
  border-radius: 15px;
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
  transition: all 0.3s ease-in-out;
}

.main-container .Event-tiles .Event-tile:hover img {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  transition: all 0.3s ease-in-out;
}

.main-container .Event-tiles .Event-tile .text-topEvent {
  position: relative;
  display: block;
  z-index: 20;
  background-color: #eea02759 !important;
  height: 40%;
  width: 100%;
  bottom: 80px;
  text-align: center;
}

.main-container .Event-tiles .Event-tile .text-topEvent h4,
.main-container .Event-tiles .Event-tile .text-topEvent p {
  color: white;
}

@media (max-width: 1400px) {
  .main-container {
    flex-wrap: nowrap;
    margin-bottom: 0;
  }
}

/* @media(min-width:1055px)and(max-width:1400px)
{
    .main-container{
        flex-wrap: nowrap;
        margin-bottom: 900px;
    }
}

@media(max-width:1050px)
{
    .main-container{
        margin-bottom: 1300px;
    }
} */

.EventSection {
  min-height: 400px !important;
  margin-bottom: 30px !important;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
  margin-top: 4px;
}

.EventlandingCard {
  border-radius: 10px;
  box-shadow: 0 3px 30px 0 rgb(0 0 0 / 10%);
  background-color: #ffffff;
  padding: 25px 25px 25px;
}
.appendBottom20 {
  margin-bottom: 20px;
}
.appendBottom20 {
  margin-bottom: 20px;
}

.SuperFlex {
  display: flex;
}
.blackText {
  color: #000;
}
.latoBold {
  font-weight: 700;
}

.SuperFlex.perfectCenter {
  align-items: center;
  justify-content: center;
}

.appendLeft30 {
  margin-left: 30px;
}

.superOffersTab {
  border-bottom: solid 1px #c6c6c6;
}

.superOffersTab li.active {
  border-bottom: solid 3px #008cff;
  background-color: transparent;
}
.superOffersTab li {
  margin-right: 15px;
  border-bottom: solid 3px #fff;
  transition: all 0.2s linear;
  position: relative;
  padding-right: 15px;
}
.superOffersTab li.active a {
  color: #008cff;
}

.SuperFlex.hrtlCenter {
  align-items: center;
}
.SuperFlex.column {
  flex-direction: column;
}
.EventlandingCardSlider {
  margin-right: -25px;
  margin-left: -15px;
  margin-bottom: -15px;
}

.EventSection .slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  height: 400px;
  padding: 0;
}

.EventlandingCard h2{
    font-weight: 700;
    font-size: 32px;
}
.EventlandingCardSlider .slick-slider .slick-prev {
  right: 69px;
}
.EventlandingCardSlider .slick-slider .slick-prev {
  width: 36px;
  height: 36px;
  border-radius: 20px 0 0 20px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #f1f1f1;
  border-right: none;
  box-shadow: -1px 0px 3px 0 rgb(0 0 0 / 10%);
}
.page-section {
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 50px;
}
.EventlandingCardSlider .slick-slider .slick-prev {
  right: 69px;
}

.EventlandingCardSlider .slick-slider .slick-prev {
  width: 36px;
  height: 36px;
  border-radius: 20px 0 0 20px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #f1f1f1;
  border-right: none;
  box-shadow: -1px 0px 3px 0 rgb(0 0 0 / 10%);
}
.EventlandingCardSlider .slick-slider button {
  position: absolute;
  top: -45px !important;
  width: 40px;
  height: 40px;
  background: transparent;
  box-shadow: none;
  font-size: 0;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 0;
  border-color: #f1f1f1 !important;
}

.EventlandingCardSlider .slick-disabled.slick-next:before,
.EventlandingCardSlider .slick-disabled.slick-prev:before {
  opacity: 0.3;
}

.EventlandingCardSlider .slick-prev:before {
  transform: rotate(135deg) !important;
  -webkit-transform: rotate(135deg);
  position: relative;
  right: 0;
  top: 0px;
  content: '';
}
.EventlandingCardSlider .slick-next:before,
.EventlandingCardSlider .slick-prev:before {
  border: solid #008cff;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  position: relative;
  right: 0;
  top: 0px;
  content: '';
}

.EventlandingCardSlider .slick-slider .slick-next {
  width: 36px;
  height: 36px;
  border-radius: 0px 20px 20px 0px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #f1f1f1;
  border-left: none;
  box-shadow: 2px 0px 3px 0 rgb(0 0 0 / 10%);
  z-index: 0;
  filter: none;
}
slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}
.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}

.slick-initialized .slick-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}
.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
.slick-initialized .slick-slide > div {
  display: inline-flex;
  width: 100%;
}
.EventlandingCard .EventlandingCardSlider .slideItem {
  padding: 40px 20px 10px 0;
  border: none;
  outline: none;
}
.EventlandingCardSlider .slideItem .itemCard {
  border-radius: 6px;
  box-shadow: 0 1px 6px 0 rgb(0 0 0 / 20%);
  background-color: #ffffff;
  margin: 5px 10px 5px 2px;
  padding: 0 15px 15px;
  cursor: pointer;
}
.soCardHeight {
  min-height: 170px;
}

.cardInnerInfo {
  min-height: 140px;
}

.EventlandingCardSlider .slideItem .itemThumb {
  width: 120px;
  margin-top: -25px;
}

.slideItem .itemImgWrap {
  width: 100%;
  height: 108px;
  border-radius: 50px;
  background: linear-gradient(90deg, #dfdddf, #efefef);
  background-size: 400% 400px;
}
.makeRelative {
  position: relative;
}

.slideItem .itemThumb img {
  border-radius: 50px;
}
.slick-slide img {
  display: block;
  width: 100%;
  height: 260px;
  object-fit: cover;
}

.EventlandingCardSlider .slideItem .itemDesc {
  width: 290px;
}

.SuperFlex.left {
  justify-content: flex-start;
}

.redBdr {
  width: 100px;
  height: 1px;
  background-color: #e1e1e1;
  display: block;
}
.redBdr em {
  width: 60px;
  height: 2px;
  background-color: #deb956;
  display: block;
  margin: auto;
}
.appendBottom10 {
  margin-bottom: 10px;
}
.greyText {
  border-color: #9b9b9b;
}

.SuperFlex.hrtlCenter {
  align-items: center;
}
.tcApplyText {
  width: 120px;
  text-align: center;
}
.darkGreyText {
  color: #4a4a4a;
}
.SuperFlex.spaceBetween {
  justify-content: space-between;
}

.SuperFlex .flexOne {
  flex: 1;
}
.flexOne {
  flex: 1;
}
.pushRight {
  margin-left: auto;
}

.pushRight a {
  color: #008cff;
  cursor: pointer;
}

.CouponDetail {
  font-size: 12px;
}

.buildTripSection {
  min-height: 400px !important;
  margin-bottom: 50px !important;
  margin-top: 40px;
}
.BuildlandingCard {
  border-radius: 16px;
  box-shadow: 0 3px 30px 0 rgb(0 0 0 / 10%);
  padding: 25px 40px 40px;
}
.eventPara{
    font-size: 13px;
    color: #000;
    text-align: left;
    font-weight: 400;
    margin-bottom: 5px;
}

@media only screen and (min-width:991px) and (max-width:1199px){
  
  .Event-tile .text-topEvent h4 {
    font-size: 15px!important;
    margin-bottom: 2px;
    color: #000;
    font-family: 'Arimo', sans-serif;
}
.Event-tile .text-topEvent {
  padding: 10px;
  height: 100px;
}


}

@media only screen and (min-width:768px) and (max-width: 990px){

 .Event-tile .text-topEvent h4 {
    font-size: 15px !important;
    margin-bottom: 2px;
    color: #000;
    font-family: 'Arimo', sans-serif;
}
.Event-tile .text-topEvent {
  padding: 10px;
  height: 100px;
}


}

@media only screen and (min-width:480px) and (max-width: 767px){
  .Event-tile .text-topEvent {
    padding: 10px;
    height: 110px;
  }
  .EventlandingCard h2 {
    font-weight: 700;
    font-size: 25px;
}
#mobileSearchBtn {
  display: block;
  padding: 6px 32px;
  background-color: lightblue;
  border-radius: 25px;
  border: none;
  top: 11px;
  left: 88px;
  font-size: 14px;
  margin-bottom: 56px;
}
  .font18 {
    font-size: 14px !important;
    line-height: 18px !important;
    display: block;
    width: 100%;
}
.SuperFlex {
  display: block;
}
.SuperlandingCard {
  border-radius: 10px;
  box-shadow: 0 3px 30px 0 rgb(0 0 0 / 10%);
  background-color: #ffffff;
  padding: 25px 25px 25px;
  height: 100vh;
}

}

@media only screen and (max-width: 479px){

  .Event-tile .text-topEvent h4 {
    font-size: 13px !important;
    margin-bottom: 2px;
    color: #000;
    font-family: 'Arimo', sans-serif;
}
.Event-tile .text-topEvent p {
  font-size: 9px !important;
  margin-bottom: 2px;
  color: #000;
  font-family: 'Arimo', sans-serif;
}
.EventlandingCard h2 {
  font-weight: 700;
  font-size: 18px;
}
.SuperlandingCardSlider .slideItem .itemThumb {
  max-width: 100% !important;
  margin: 12px 0px!important;
}
#mobileSearchBtn {
  display: block;
  padding: 6px 32px;
  background-color: lightblue;
  border-radius: 25px;
  border: none;
  top: 11px;
  left: 88px;
  font-size: 14px;
  margin-bottom: 56px;
}
.eventPara {
  font-size: 10px;
  color: #000;
  text-align: left;
  font-weight: 400;
  margin-bottom: 5px;
}
.SuperFlex {
  display: block;
}
.superOffersTab li {
  margin-right: 14px;
  border-bottom: solid 3px #fff;
  transition: all 0.2s linear;
  position: relative;
  margin-bottom: 10px;
}
.font18 {
  font-size: 14px;
  line-height: 18px;
  display: block;
  width: 76%;
}

}
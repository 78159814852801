.buttons{
    background-color: white !important;
    color: black;
    width: 10%;
    z-index: 10;
    position: fixed;
    right: 150px;
    top: 447px;

}
.buttons-stop{
    position: absolute;
    background-color: white !important;
    color: black;
    width: 70%;
    z-index: 10;
    height: 30%;
    right: 30px;
    top: 260px;
    border-radius: 12px;
    padding: 5px;
    align-items: center;
    padding: 20px;
}

.buttons-stop .Adults, .buttons-stop .children{
    display: flex;
    flex-flow: row;
    justify-content: space-between;
}

.buttons-stop .Adults button,.buttons-stop .children button{
    border-radius: 12px;
    padding: 0 5px;
    height: 25px;
    width: 25px;
    font-size: 12px;
    background-color: white;
}



.buttons-invisible{
    display: none;
    background-color: white !important;
    color: black;
    width: 10%;
    z-index: 10;
    position: fixed;
    right: 150px;
    top: 200px;
}

.buttons .Adults,.buttons .children{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.buttons .Adults button,.buttons .children button{
    border-radius: 12px;
    padding: 0 5px;
    height: 25px;
    width: 25px;
    font-size: 12px;
}

.buttons .Adults p,.buttons .children p{
    font-size: 16px;
}

@media(max-width:1360px){
    .buttons .Adults p,.buttons .children p{
        font-size: 10px;
    }
}

@media(max-width:1200px){
    .buttons .Adults button,.buttons .children button{
        border-radius: 12px;
        padding: 0 5px;
        height: 20px;
        width: 20px;
        font-size: 8px;
    }
}
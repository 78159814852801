.add-accomadation{
    width: 800px;
    background-color: white;
    border-left: 2px solid #deb956;
    font-size: large;
    display: flex;
    justify-content: space-between;
    padding: 0;
    padding-left: 20px;
}

.add-accomadation  .add{
    margin: 0 !important;   
    border: none;
    padding: 20px;
    background-color: #deb956;
}
.container-wallet{
    display: flex;
    flex-flow: column;
    background-color: #f2f2f2;
    height: 100%;
}

.container-wallet .wallet-dropdown select{
    background: transparent;
    margin-left: 100px;
    border-radius: 20px;
    padding: 5px 10px;
    font-size: larger;
}


.container-wallet .wallet-dropdown p{
    background: transparent;
    margin-top: 10px;
    margin-left: 20px;
    font-size: larger; 
}

.container-wallet .wallet-details{
    display: flex;
    flex-flow: column;
    width: 35%;
    margin: 10px;
}

.container-wallet .wallet-details .wallet-balance{
    display: flex;
    flex-flow: column;
}

.container-wallet .wallet-details .wallet-balance .balance{
    display: flex;
    flex-flow: column;
    background-image: linear-gradient(255deg, #3023ae, #c86dd7);
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    margin-left: 100px;
}

.container-wallet .wallet-details .wallet-balance .balance-details{
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin-left: 100px;
    padding: 15px;
    background-color: white;
}

.container-wallet .wallet-details .wallet-balance .balance-details .unrestricted{
    color: white;
    background-color: #249995;
    border: none;
    border-radius: 10px;
    padding: 1px 8px;
    font-weight: 600;
}

.container-wallet .wallet-details .wallet-balance .balance-details .restricted{
    color: white;
    background-color: gray;
    border: none;
    border-radius: 10px;
    padding: 1px 8px;
    font-weight: 600;

}

.container-wallet .wallet-details .Questions{
    display: flex;
    flex-flow: column;
    height: auto;
    background-color: white;
    margin-left: 100px;
    margin-top: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.container-wallet .wallet-details .Questions .question{
    margin-top: 20px;
    display: flex;
    flex-flow: column;
   margin-bottom: 0;
    padding: 20px;
}

.container-wallet .wallet-details .Questions .question .answerInVisible{
    display: none;
}

.container-wallet .wallet-details .Questions .question .answerVisible{
    display: block;
}

.Some-Content{
    position: absolute;
    width: 700px;
    height: 1000px;
    background-color: white;
    display: inline-block;
    top:200px;
    right: 100px;
    border-radius: 20px;
}
.Header-Container{
    display: flex;
    justify-content: space-around;
    padding: 40px;
    align-items: center;
    margin: 0;
}

.Header-Container .right{
    display: flex;

}

.Header-Container .right div{
    margin: 0 10px;
    color: grey;
    font-weight:500;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}


.Header-Container .right div:hover{
    color: black;
    transition: all 0.3s ease-in-out;
}

.Header-Container .right div img{
    height: 30px;
    width: 30px;
}


.makeFlex1 {
    display: flex;
    justify-content: space-between;
    max-width: 1300px;
}

.Movable-Container {
    margin: 0;
    width: 100%;
    z-index: 102;
    position: fixed;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
    padding-right: 40px;
    padding-left: 40px;
    padding-top: 20px;
    background-color: white;
}

.Movable-Container .right{
    display: flex;
    margin-top: 20px;
    padding-right: 27px;
}

.Movable-Container .right div{
    margin: 0 12px;
    color: grey;
    font-weight:500;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}


.Movable-Container .right div:hover{
    color: black;
    transition: all 0.3s ease-in-out;
}

.Movable-Container .right div img{
    height: 30px;
    width: 30px;
}


.hotel-availablity{
    border: 1px solid #f1f1f1;
    margin-top: 40px;
    padding: 10px;
}
.hotel-availablity table{
    margin-top: 20px;
}

.hotel-availablity table .head{
    border: 2px solid #0074B7 !important;
    text-align: center;
    padding: 20px !important;
    justify-content: space-evenly;
}

.hotel-availablity table tr .image-room-details{
    display: flex;
    justify-content: space-evenly;
}

.hotel-availablity table tr .image-room-details img{
    height: 100px;
    width: 120px;
}

.hotel-availablity table tr .image-room-details .details{
    display: flex;
    flex-flow: column;
    margin-left: 20px;
}

.hotel-availablity table tr .none-refundable{
    display: flex;
    flex-flow: column;
}

.hotel-availablity table tr{
    display: flex;
}
.hotel-availablity table tr .rooms-book{
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
}

.hotel-availablity table tr .rooms-book #rooms{
    width: 150px;
    padding:8px 20px;
    border-radius: 20px;
}

.hotel-availablity table tr .rooms-book .book-now{
    padding: 8px 35px;
    border-radius: 2px;
    border:none;
    background-color: #0074B7;
    color: white;
    transition: all 0.3s ease-in-out;
}

.hotel-availablity table tr .rooms-book .book-now:hover{
    padding: 8px 35px;
    border-radius: 2px;
    border:none;
    background-color:#4da05f;
    color: white;
    transition: all 0.3s ease-in-out;
}

.page-section.bottom35 {
    margin-bottom: 35px !important;
}
.page-section.bottom35 {
    margin-bottom: 35px !important;
    margin-top: 40px;
}
.page-section {
    width: 1200px;
    margin: 0 auto;
}

._RoomType .gap {
    height: 0;
    background: #fff;
    position: sticky;
    top: 125px;
    left: 0;
    z-index: 2;
}
._RoomType .roomheader {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background: #f0f0f0;
    position: sticky;
    top: 120px;
    left: 0;
    width: 100%;
    border: solid 1px #bababa;
    display: flex;
    z-index: 1;
}

._RoomType .titleWidth {
    width: 260px;
    border-right: solid 1px #bababa;
    flex-direction: column;
    flex-shrink: 0;
    position: relative;
}

._RoomType .titleWidth, ._RoomType .titleLastWidth {
    display: flex;
    padding: 16px 20px;
}
._RoomType .titleWidth {
    width: 260px;
    border-right: solid 1px #bababa;
    flex-direction: column;
    flex-shrink: 0;
    position: relative;
}
._RoomType .titleWidth, ._RoomType .titleLastWidth {
    display: flex;
    padding: 16px 20px;
}
.latoBold {
    font-weight: 700;
}
.font12 {
    font-size: 12px;
    line-height: 12px;
}
.capText {
    text-transform: uppercase;
}
._RoomType .titleWidth:last-child {
    border: 0;
    letter-spacing: -0.8px;
}

._RoomType .roomWrap {
    display: flex;
    width: 100%;
    border-left: solid 1px #bababa;
    border-right: solid 1px #bababa;
    border-bottom: solid 8px #bababa;
}


._RoomType .roomLeft {
    width: 260px;
    border-right: solid 1px #bababa;
    padding: 0 20px 20px 20px;
}
._RoomType .roomLeft, ._RoomType .roomRight {
    display: flex;
    flex-direction: column;
}

._RoomType .sticky {
    position: sticky;
    position: -webkit-sticky;
    top: 165px;
}

._RoomType .roomLeft h2 {
    font-weight: 900;
    padding: 12px 0;
    background: #fff;
    position: sticky;
    position: -webkit-sticky;
    top: 150px;
    font-size: 16px;
    color: #555;
}

.appendBottom10 {
    margin-bottom: 10px;
}
.hsmakeFlex {
    display: flex;
}
.appendBottom5 {
    margin-bottom: 5px;
}

.oval {
    width: 6px;
    height: 6px;
    background-color: #d8d8d8;
    display: inline-block;
    margin-right: 8px;
    border-radius: 6px;
    margin-top: 6px;
}

.hsmakeFlex.hrtlCenter {
    align-items: center;
}

.latoBlack {
    font-weight: 900;
}
.font12 {
    font-size: 12px;
    line-height: 12px;
}
.capText {
    text-transform: uppercase;
}

.spriteRate.linkArrowIcon {
    width: 18px;
    height: 14px;
    background-position: -313px -136px;
}

.spriteRate.linkArrowIcon {
    width: 18px;
    height: 14px;
    background-position: -313px -136px;
}
.spriteRate {
    background: url(../../../assets/images/detailPage.png) no-repeat;
    display: inline-block;
    background-size: 450px 750px;
    font-size: 0px;
    flex-shrink: 0;
}

._RoomType .roomRight {
    width: 940px;
}

._RoomType .roomLeft, ._RoomType .roomRight {
    display: flex;
    flex-direction: column;
}

._RoomType .multiRoomRow:last-child {
    border-bottom: solid 0px #bababa;
}
._RoomType .multiRoomRow:last-child {
    border-bottom: solid 0 #bababa;
}
.bdrBottom:last-child {
    border-bottom: solid 0px #bababa;
}
._RoomType .multiRoomRow {
    border-bottom: solid 1px #bababa;
    display: flex;
    transition: all 0.3s linear;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
}
/* ._RoomType .recRoom {
    background-color: #fff6e7;
} */

.hsmakeFlex.column {
    flex-direction: column;
}
._RoomType .recRoomTag {
    border-radius: 8.9px;
    background-image: linear-gradient(
259deg
, #3a7bd5, #00d2ff);
    font-weight: bold;
    font-size: 10px;
    padding: 2px 8px;
    position: relative;
}
._RoomType .roomIconWrap {
    width: 36px;
    display: flex;
    justify-content: center;
}
.spriteRate.roomBreakfastIcon {
    width: 28px;
    height: 30px;
    background-position: -4px -91px;
}
.appendBottom4 {
    margin-bottom: 4px;
}
.hsmakeFlex .flexOne {
    flex: 1;
}

.hsmakeFlex {
    display: flex;
}
.appendBottom15 {
    margin-bottom: 15px;
}
._RoomType .roomIconWrap {
    width: 36px;
    display: flex;
    justify-content: center;
}

.pwaSprite.freeCancellation {
    background-position: -337px -51px;
    width: 15px;
    height: 24px;
}
.pwaSprite.freeOffer{
    background-position: -203px -206px;
    width: 26px;
    height: 24px;
    cursor: pointer;
}
.pwaSprite {
    background: url(../../../assets/images/detailPage.png) no-repeat;
    display: inline-block;
    background-size: 450px 750px;
    font-size: 0px;
    flex-shrink: 0;
}

.greenText {
    color: #00a19c;
}
.rohan{
    width:350px;
    height:auto;
    background-color: #010101  !important;
    text-align: justify;
    font-weight: 500;
}

.te9c72e40-ad4b-4f4d-83ab-930eee6a4b34 {
    color: #fff;
    background: #010101 !important;
    border: 1px solid transparent;
}
.latoBold {
    font-weight: 700;
}

/* ._RoomType .sticky p img{
    display: none;
}

._RoomType .sticky p {
    font-size: 10px;
    line-height: 10px;
} */

.OpenCancel{
    cursor: pointer;
    font-size: 12px;
    color:#065af3 !important;
    margin-top: -6px;
}

._RoomType .includeList {
    margin: 37px 0 0 0;
    display: flex;
    flex-direction: column;
}

.slctRmDropdown__list--item:hover {
    background-color: #e2f2ff;
}

._RoomType .includeList li {
    display: flex;
    margin-bottom: 10px;
    position: relative;
    word-break: break-word;
}
._RoomType .includeList li:before {
    content: '';
    display: block;
    width: 6px;
    height: 10px;
    border: solid #4a4a4a;
    opacity: 0.7;
    border-width: 0 2px 2px 0;
    transform: rotate(
45deg
);
    margin: 4px 10px 0 0;
    flex-shrink: 0;
}

._RoomType .titleLastWidth {
    width: 418px;
    display: flex;
}

.appendTop40 {
    margin-top: 40px;
}
.font22 {
    font-size: 22px;
    line-height: 22px;
}

.appendBottom5 {
    margin-bottom: 5px;
}

.grayText {
    color: #9b9b9b;
}

.font12 {
    font-size: 12px;
    line-height: 12px;
}

.hsmakeFlex.perfectCenter {
    align-items: center;
    justify-content: center;
}

a.primaryBtn2 {
    color: #fff !important;
}

.primaryBtn2 {
    border-radius: 17px;
    background-image: linear-gradient(
96deg
,#53b2fe,#065af3);
    box-shadow: 0 1px 7px 0 rgb(0 0 0 / 20%);
    display: inline-block;
    flex-shrink: 0;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
    padding:8px 10px;
    outline: 0;
    border: 0;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
}

.appendBottom15 {
    margin-bottom: 15px;
}
.dealOfDay {
    background-color: rgb(255, 253, 233);
    position: relative;
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
    padding: 5px 16px;
    margin: -16px -20px 15px;
}

.latoBold {
    font-weight: 700;
}

._RoomType .dealOfDay:after {
    top: 100%;
    left: 10%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-top-color: rgb(255, 253, 233);
    border-width: 15px;
    margin-left: -15px;
}

.modalCont {
    width: 750px;
    background: #fff;
    padding: 30px;
    border-radius: 4px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 999999;
}

.closeButton {
    position: absolute;
    right: 30px;
    top: 30px;
    width: 18px;
    height: 18px;
    cursor: pointer;
    color:red;
    font-size: 15px;
}

.modalCont h4 {
    font-size: 22px;
    font-weight: 900;
    color: #000;
    margin-bottom: 5px;
}




.modalContent {
    max-height: 450px;
    overflow-y: auto;
    padding-top: 20px;
}

.backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(0,0,0,0.7);
    z-index: 99999;
}

.cncpolicyTbl {
    border-radius: 4px;
    border: solid 1px #D0D5DF;
    background-color: #FFFFFF;
    overflow: hidden;
    margin-bottom: 15px;
}

.cncpolicyTbl__head {
    border-bottom: solid 1px #D0D5DF;
    display: flex;
    font-size: 11px;
    font-weight: 900;
}

.cncpolicyTbl__col:first-child {
    border-right: solid 1px #D0D5DF;
}
.cncpolicyTbl__head .cncpolicyTbl__col {
    background-color: #F1F3F7;
}
.cncpolicyTbl__col {
    width: 50%;
    padding: 6px 10px;
}

.cncpolicyTbl__row {
    border-bottom: solid 1px #D0D5DF;
}
.cncpolicyTbl__row {
    display: flex;
}

.appendTop20 {
    margin-top: 20px;
}

.comboWrap {
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgb(138 107 118 / 8%);
    border: solid 1px #bababa;
    display: flex;
    width: 100%;
}
.left {
    width: calc(100% - 328px);
    background-color: #bfd7ed94;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.appendBottom20{
    margin-bottom: 12px;
}

.padding20 {
    padding: 20px;
}

.makeRelative {
    position: relative;
}

.bdrBottom {
    border-bottom: solid 1px #bababa;
}

.comboTitle {
    font-size: 20px;
    width: calc(100% - 200px);
}
.roomRow {
    padding: 20px;
    border-bottom: solid 1px #bababa;
}

.width33 {
    width: 33%;
}

.roomTag {
    border-radius: 36px;
    background-color: #4a4a4a;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    font-weight: 700;
    padding: 4px 10px;
    text-transform: uppercase;
    /* width: 120px; */
    flex-shrink: 0;
    max-height: 20px;
    align-self: flex-start;
}

.types {
    display: flex;
    flex-direction: column;
    font-weight: 300;
}

.types li {
    padding-top: 6px;
    font-size: 12px;
    display: flex;
    align-items: center;
}

.types li:before {
    content: '';
    display: block;
    width: 3px;
    height: 6px;
    border: solid #4a4a4a;
    border-width: 0 1px 1px 0;
    transform: rotate(
45deg
);
    margin-right: 10px;
}
.roomRow {
    padding: 20px;
    border-bottom: solid 1px #bababa;
}

.roomRow:last-child {
    border-bottom: solid 0px #bababa;
}

.priceBreakUp {
    width: 328px;
    display: flex;
    flex-direction: column;
    border-left: solid 1px #bababa;
    position: relative;
    padding-bottom: 30px;
}

.breackUpHeading {
    padding: 17px 20px 23px 20px;
}

.paddingLR20 {
    padding-right: 20px;
    padding-left: 20px;
}
.hsmakeFlex.spaceBetween {
    justify-content: space-between;
}

.overlapBtn {
    position: absolute;
    width: 70%;
    padding: 5px;
    margin: 0 auto;
    /* justify-content: center; */
    /* align-items: center; */
    right: 20px;
    /* left: 49px; */
    margin-top: 10px;
}

.basicCost {
    /* width: calc(100% - 140px); */
    display: flex;
    flex-direction: column;
    flex: 1;
}

.roomPrice {
    /* width: 70px; */
    text-align: right;
    margin-left: auto;
    flex-shrink: 0;
}

.roomLeftCont {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.roomLeftContRow {
    display: flex;
    border-bottom: 6px #bababa solid;
}

.Middle {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.multiRoomRow {
    border-bottom: solid 1px #bababa;
    display: flex;
    transition: all 0.3s linear;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
}

.col1 {
    width: 300px;
    padding: 20px 20px 0 20px;
    border-right: solid 1px #bababa;
}

.col2 {
    width: 188px;
    padding: 14px 20px 0 20px;
    border-right: solid 1px #bababa;
}

.col3 {
    width: 238px;
    padding: 20px 20px 0 20px;
    border-right: solid 1px #bababa;
    transition: all 0.3s linear;
}

.col4 {
    width: 180px;
    text-align: center;
    position: relative;
    padding-bottom: 15px;
}

.roomRightCont {
    width: 219px;
    flex-shrink: 0;
    background: #bfd7ed94;
    border-left: solid 1px #bababa;
    padding: 0 20px;
    position: relative;
    display: flex;
}

.roomRightContSticky {
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    bottom: 20px;
    width: 100%;
    align-self: flex-end;
}

.slctRmDropdown__input {
    background: #fff;
    border: 1px solid #bababa;
    border-radius: 2px;
    box-shadow: 0 4px 8px rgb(138 107 118 / 8%);
    cursor: pointer;
    font-size: 10px;
    font-size: 14px;
    padding: 10px;
    position: relative;
    width: 100%;
    outline: 0;
}

.slctRmDropdown__input__option {
    border-bottom: 1px solid #bababa;
    cursor: pointer;
    padding: 10px;
}
.slctRmDropdown {
    position: relative;
}

.appendTop10 {
    margin-top: 10px!important;
}

.slctRmDropdown__input:after {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #000;
    content: "";
    height: 0;
    position: absolute;
    right: 10px;
    top: 17px;
    width: 0;
}

.slctRmDropdown__list {
    background-color: #fff;
    border: 1px solid #bababa;
    border-radius: 0 0 2px 2px;
    box-shadow: 0 4px 8px 0 rgb(138 107 118 / 8%);
    left: 0;
    position: absolute;
    top: 37px;
    width: 100%;
    z-index: 100000000;
}

.slctRmDropdown__list--item {
    border-bottom: 1px solid #bababa;
    cursor: pointer;
    padding: 10px;
}

.slctRmDropdown__list--item:last-child {
    border-bottom: 0;
    border-radius: 0 0 2px 2px;
}


*, :after, :before {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-font-smoothing: antialiased;
    margin: 0;
    padding: 0;
}

.txtNoRoomSelected {
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    margin-top: 30px;
}

.roomBlank.nextRoom {
    border-color: #0084ff;
    animation: zoominoutsinglefeatured 1s 1;
}

.roomBlank {
    border-radius: 4px;
    background-color: #fff;
    border: dashed 1px #a9d4f6;
    padding: 15px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0084ff;
    font-weight: 700;
    font-size: 12px;
    cursor: pointer;
}
.roomImg {
    width: 100%;
    border-radius: 4px;
    cursor: pointer;
}
.selectedRoom {
    border-radius: 4px;
    background: #ffffff;
    border: dashed 1px #0084ff;
    padding: 15px 12px;
    position: relative;
    cursor: pointer;
}

.sprite.linkArrowIcon {
    width: 18px;
    height: 14px;
    background-position: -313px -136px;
}

.dealOfDayNew {
    background-color: rgb(255, 253, 233);
    position: relative;
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
    padding: 5px 16px;
    /* margin: -16px -20px 15px; */
}

.btnCloseRoom {
    width: 24px;
    height: 24px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 22%);
    display: inline-block;
    position: absolute;
    right: -12px;
    top: -12px;
    border-radius: 50%;
    cursor: pointer;
}
.btnCloseRoom:before {
    transform: rotate(
45deg
);
}
 .btnCloseRoom:before, .btnCloseRoom:after {
    position: absolute;
    left: 11px;
    content: ' ';
    height: 12px;
    width: 2px;
    background-color: #0084ff;
    top: 6px;
}

.btnCloseRoom:after {
    transform: rotate(
-45deg
);
}
.primaryBtn.btnAddRoom {
    padding: 8px 12px;
    width: 115px;
}

.selectedRoomRow {
    display: flex;
    flex-direction: column;
}

.font12 {
    font-size: 12px;
    line-height: 12px;
}

.latoRegular {
    font-weight: 400;
    
}
.lineHight16 {
    line-height: 16px !important;
}
.btnConfirmBooking {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    border-radius: 34px;
    box-shadow: 0 1px 7px 0 rgb(0 0 0 / 20%);
    border: solid 1px #0084ff;
    font-weight: 900;
    text-transform: uppercase;
}
.primaryBtn {
    color: #fff !important;

}

.col4 .primaryBtn {
    font-size: 11px;
}

.roomLeftContRow:last-child {
    border-bottom: 0;
}

.primaryBtn.btnAddRoom.btnDisbaled {
    background-color: #ffffff;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 40%);
    color: #bae2fc !important;
    background-image: none;
    pointer-events: none;
}
.carousel .slide img{
    width: 232px !important;
    height: 140px !important;
}
.carousel .slider-wrapper {
    overflow: hidden;
    margin: auto;
    width: 232px;
    transition: height .15s ease-in;
    height: 140px;
}

.TripContainer{
    display: flex;
    flex-flow: column;
    margin-top: 80px;
}

.TripContainer .gap{
    display: flex;
    align-items: center;
    background: linear-gradient(261deg,#7dbfcc,#5ee7cd);
    height: 200px;
    width: 100%;
}

.TripContainer .gap select{
    background: transparent;
    margin-left: 100px;
    border-radius: 20px;
    padding: 5px 10px;
    font-size: larger;
}
.TripContainer .gap p{
    background: transparent;
    margin-top: 10px;
    margin-left: 20px;
    font-size: larger; 
}

.TripContainer .nav-trip{
    position: -webkit-sticky;
     position: sticky;
     top: 50px;
    /* z-index: 2; */
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    /* box-shadow: 2px 3px 4px gray; */
    padding: 50px 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 1px solid gray;
    border-bottom: 1px solid rgb(212, 207, 207);
    /* margin-bottom: 1140px; */
}

.TripContainer .nav-trip ul{
    display: flex;
    flex-flow: row;

}
.TripContainer .nav-trip ul li{
    margin: 5px;
    font-size: large;
    font-weight: 600;
    cursor: pointer;
    transition: all ease-in-out 0.3s;
    border : 2px solid transparent;
}
.TripContainer .nav-trip ul li:hover,a.active{
    transition: all ease-in-out 0.3s;
}



.TripContainer .nav-trip input[type=text] {
    float: right;
    padding: 6px;
    border: 1px solid gray;
    border-radius: 4px;
    margin-top: 8px;
    margin-right: 16px;
    font-size: 17px;
  }




  #planBtn{
      
      padding: 10px 20px;
      background-color: rgb(42, 42, 197);
      color: white;
      border: none;
      border-radius: 20px;
      transition: all ease-in-out 0.3s;
  }
  #planBtn:hover{
    padding: 10px 20px;
    background-color: white;
    color: rgb(42, 42, 197);
    border: none;
    border-radius: 20px;
    border: 1px solid rgb(42, 42, 197);
    transition: all ease-in-out 0.3s;
}

.TripContainer .cancelled{
    z-index : 0;
    background-color: white;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-items: center;
    width: 70%;
    padding: 50px 40px;
    margin-top: 20px;
    height: 400px;
    border:1px solid gray;
    border-radius: 10px;
  }
  
  .TripContainer .completed{
    z-index: 0;
    background-color: white;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-items: center;
    width: 70%;
    padding: 50px 40px;
    margin-top: 20px;
    height: 400px;
    border:1px solid gray;
    border-radius: 10px;
  }

  #topBtn{
      margin-top: 40px;
      padding: 20px 50px;
        border-radius: 50px;
        background-color: white;
        color: rgb(42, 42, 197);
        font-size: larger;
        font-weight: bold;
        border: 1px solid rgb(42, 42, 197);
        transition: all ease-in-out 0.3s;
  }

  #topBtn:hover{
    margin-top: 40px;
    padding: 20px 50px;
      border-radius: 50px;
      background-color: rgb(42, 42, 197);
      color: white;
      font-size: larger;
      font-weight: bold;
      box-shadow: 0 3px 22px 0 rgb(0 0 0 / 20%);
      transition: all ease-in-out 0.3s;
    border: none;
}
.tripStatusContainer {
    margin-top: -120px;
    position: sticky;
    top: 0;
    z-index: 2;
    padding-top: 20px;
}

.tripStatusWrap {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    height: 80px;
    padding: 20px 30px 0;
    background: #fff;
    border-radius: 8px 8px 0 0;
    box-shadow: 0 2px 20px 0 rgb(0 0 0 / 10%);
}
.tripStatusWrap ul, .tripStatusWrap ul li {
    display: flex;
}



.tripStatusWrap ul li a {
    margin-right: 40px;
    padding-right: 10px;
    cursor: pointer;
    position: relative;
}

.tripStatusWrap ul li .tripStatusWrap__statusText {
    display: flex;
    flex-direction: column;
}

.tripStatusWrap ul li.active .tripStatusWrap__statusText span:first-child {
    color: #202e4a;
}

.tripStatusWrap ul li.active .tripStatusWrap__statusText:not(.failed) span:last-child {
    color: #249995;
}

.tripStatusWrap ul li.ColorPlannedTrips a:after {
    background: #087abe;
    width: 100%;
    color: #087abe;
}

.tripStatusWrap ul li.ColorUpcoming a:after {
    background: #5ee7cd;
    width: 100%;
    color: #5ee7cd;
}

.tripStatusWrap ul li.ColorCancelled a:after {
    background: #b4b7bd;
    width: 100%;
    color: #b4b7bd;
}
.tripStatusWrap ul li.ColorCompleted a:after {
    background: #f2cb49;
    width: 100%;
    color: #f2cb49
}

.tripStatusWrap ul li a:after {
    width: 0;
    height: 5px;
    background: transparent;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    content: "";
    transition: all .3s ease-in-out;
    transform-origin: center center;
}

.mytripmakeFlex {
    display: flex;
}

.contentWrapper.myTrips {
    position: relative;
    width: 1200px;
    margin-bottom: 200px;
    margin-top: 0;
    flex-direction: column;
    border-radius: 10px;
}
.contentWrapper {
    width: 1000px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.tripListingWrap .myTripLstngLobCrd.forUpcoming {
    border-radius: 0 0 10px 10px;
}

.tripListingWrap .myTripLstngLobCrd.forCancelled {
    border-radius: 0 0 10px 10px;
}

.tripListingWrap .myTripLstngLobCrd.forCompleted{
    border-radius: 0 0 10px 10px;
}

.tripListingWrap .myTripLstngLobCrd.forPlanned{
    border-radius: 0 0 10px 10px;
}

.myTripLstngLobCrd, html[dir=rtl] .myTripLstngLobCrd {
    border-radius: 10px;
    box-shadow: 0 3px 30px 0 rgb(0 0 0 / 10%);
    background-color: #fff;
}
.myTripLstngLobCrd {
    padding: 40px 30px 30px 45px;
}
.tripListingWrap .myTripLstngLobCrd {
    margin-bottom: 15px;
}
.emptyErr {
    display: flex;
    background: #fff;
    padding: 50px;
}

.emptyErr--imgCont {
    width: 120px;
    height: 120px;
    margin-right: 65px;
    overflow: hidden;
}

.emptyErr--imgCont img {
    width: 100%;
}

.myTripBtn--primary {
    color: #fff;
    box-shadow: 0 3px 4px 0 rgb(0 0 0 / 20%);
    background-image: linear-gradient(
94deg
,#53b2fe,#065af3);
outline: 0;
}
.myTripBtn {
    font-family: lato;
    font-weight: 900;
    font-size: 14px;
    padding: 12px 20px;
    border-radius: 24px;
    border: 0;
    outline: 0;
    text-decoration: upperCase;
    text-align: center;
    display: inline-block;
    text-transform: uppercase;
    cursor: pointer;
    min-width: 200px;
    flex-shrink: 0;
}

.reviewPlanned {
    height: 200px;
    background-image: linear-gradient( 
261deg
 ,#53b2fe,#0074b7);
    width: 100%;
    margin-top: 100px;
    padding-top: 20px;
}

.reviewUpcoming {
    height: 200px;
    background-image: linear-gradient(
        261deg
        ,#7dbfcc,#5ee7cd);
    width: 100%;
    margin-top: 100px;
    padding-top: 20px;
}
.reviewCancelled {
    height: 200px;
    background-image: linear-gradient(
        262deg
        ,#afb3ba,#cfcfcf);
    width: 100%;
    margin-top: 100px;
    padding-top: 20px;
}

.reviewCompleted {
    height: 200px;
    background-image: linear-gradient(
261deg
,#f09819,#f3d452);
    width: 100%;
    margin-top: 100px;
    padding-top: 20px;
}

.reviewPlannedNew {
    background-image: linear-gradient( 
261deg
 ,#53b2fe,#0074b7);
}

.reviewUpcomingNew {
    background-image: linear-gradient(
        261deg
        ,#7dbfcc,#5ee7cd);
}
.reviewCancelledNew {
    background-image: linear-gradient(
        262deg
        ,#afb3ba,#cfcfcf);
}

.reviewCompletedNew {
    background-image: linear-gradient(
261deg
,#f09819,#f3d452);
}

.reviewPlanned h4 {
    color: white;
    font-weight: bold;
}

.reviewUpcoming h4 {
    color: white;
    font-weight: bold;
}

.reviewCancelled h4 {
    color: white;
    font-weight: bold;
}

.reviewCompleted h4 {
    color: white;
    font-weight: bold;
}

.ContainerFlex {
    display: flex;
    justify-content: space-evenly;
    padding: 10px;
    width: 100%;
}

.ContainerImage{
    width: 365px;
    position: relative;
}

.ContainerImage img {
    width: 100%;
    height: 100%;
}

.paymakeFlexNew {
    display: flex;
    margin-bottom: -8px;
    justify-content: space-between;
}
#image{
    opacity: 0.8;
    transition: all ease-in-out 0.3s;
    cursor: pointer;
    height: 80%;
    width: 100%;
}

#image:hover{
    opacity: 1;
    transition: all ease-in-out 0.3s;
}
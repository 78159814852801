#container-top{
    padding: 10px;
    box-shadow: 5px 5px 5px #aaaaaa;
    border-radius: 5px;
}

.tip{
    background-color: #eea027;
    font-style: italic;
    color: black;
    display: block;
    border-radius: 3px;
    font-weight: 500;
    font-size: 13px;
    max-width: 100%;
    padding: 5px 15px;
    margin-top: 5px;
    margin-left: 20px;
}

.duration{
    color: black;
    display: block;
    border-radius: 3px;
    font-weight: 500;
    font-size: 13px;
    max-width: 100%;
    padding: 5px 15px;
    margin-top: 5px;
    margin-left: 20px;
}

.list-container{
    transition: all ease-in-out 0.3s;
}

/* .list-container:hover{
    transition: all ease-in-out 0.3s;
    background-color: lightblue;
}

.active{
    background-color: lightblue;
} */
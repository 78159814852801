.review{
    background-color: #0074B7;
    height: 200px;
    width: 100%;
    margin-top: 30px;
    padding-top: 20px;
}

.review h4{
    color:white;
    font-weight: bold;
}

.Details{
    width: 100%;
    display: flex;
    justify-content: space-between;
    z-index: 2;
    height: 100vh;
    position: absolute;
    top: 100px;
}

.landingContainer_details {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.Details .subDetails {
    width: 65%;
    height: 100%;
}

.Details .subDetails .HotelInfo {
    transition: all 1s ease-in-out;
    width: 100%;
    height: auto;
    margin: auto;
    background-color: white;
    box-shadow: 0 0 8px rgb(0 0 0 / 18%);
    border-radius: 4px;
}

.Details  .subDetails .HotelInfoShort{
    transition: all 1s ease-in-out;
    width: 100%;
    height: 10%;
    margin: auto;
    background-color: white;
    box-shadow: 0 0 8px rgb(0 0 0 / 18%);
    border-radius: 4px;
    padding:40px;
}

.Details  .subDetails .HotelInfoShort  .upper-Scroll{
    display: flex;
    justify-content: space-between;
}

.Details .subDetails .HotelInfoShort  .Detail-Hotel{
    display: none;
}

/* .Details .subDetails .HotelInfo .Detail-Hotel{

} */


.Details  .subDetails .HotelInfo  .upper-Scroll{
    display: flex;
    justify-content: space-between;
    padding: 15px 20px 15px 20px;
    border-bottom: 1px solid #ededed;
}

.Details  .subDetails .HotelInfo   .Detail-Hotel .Hotel-Name{
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    border-bottom: 1px solid #ededed;
    padding: 15px 20px 15px 20px;
}

.Details  .subDetails .HotelInfo   .Detail-Hotel .Hotel-Name .imageContainer img{
   height: 100%;
   width: 100%;
}

.Details  .subDetails .HotelInfo   .Detail-Hotel .checkInOut{
    padding: 0;
    background-color: rgba(242, 242, 242, 0.4);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.nameContainer{
    width:100%;
}
.transferPickuDrop{
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
}

.Details  .subDetails .HotelInfo   .Detail-Hotel .checkInOut .rooms{
    border-radius: 5px;
    border:1px solid black;
    padding:0 5px;
    background-color: white;
}

.Details  .subDetails .HotelInfo   .Detail-Hotel .RoomInfo{
    margin-top: 20px;
    border: solid 1px #ededed;
    border-radius: 4px;
    padding: 20px;
    margin: 20px;
}

.Details  .subDetails .HotelInfo   .Detail-Hotel .RoomInfo .Room-Name{
    border-bottom: solid 1px #d7d7d7;
}

.Details  .subDetails .HotelInfo   .Detail-Hotel .RoomInfo .Inclusions{
    padding-top: 10px;
    
}

.sRating {
    position: relative;
    display: flex;
    flex-shrink: 0;
}

.imageContainer{
    width: 150px;
    height: 90px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
}

.Details  .subDetails .HotelInfo   .Detail-Hotel .Important-Info{
    color: white;
    height: auto;
    margin-top: 20px;
    border: 1px solid #ff3e5e;
}

.Details  .subDetails .HotelInfo   .Detail-Hotel .Important-Info   .Important-Header{
    width: 100%;
    background: linear-gradient(90deg, #ff7f3f, #ff3e5e) !important;
    padding: 5px 0px 1px 10px;
}

.Details  .subDetails .HotelInfo   .Detail-Hotel .Important-Info .rules{
    padding: 20px;
}



.Details  .subDetails .GuestInfo{
    transition: all 1s ease-in-out;
    width: 100%;
    height: auto;
    margin: auto;
    background-color: white;
    box-shadow: 0 0 8px rgb(0 0 0 / 18%);
    border-radius: 4px;
    margin-top: 30px;
}

.price-header{
    padding: 15px 20px 15px 20px;
    border-bottom: 1px solid #ededed;
}


.GuestInfo .form-guest{
    padding: 15px 20px 15px 20px;
}

.Details  .subDetails .GuestInfo  .upper-Scroll{
    display: flex;
    justify-content: space-between;
    padding: 15px 20px 15px 20px;
    border-bottom: 1px solid #ededed;
}

.Details  .subDetails .GuestInfo  .form-guest-short{
    display: none;
}

.Details  .subDetails .GuestInfo  .form-guest form input:focus{
    border:transparent;
    border-radius: 10px;
}
.email_inp {
    width: 51% !important;
    margin-left: 40px !important;
}

.Details  .subDetails .GuestInfo  .form-guest form   input[type=number] {
    -moz-appearance: textfield;
  }

  .Details  .subDetails .GuestInfo  .form-guest form  input::-webkit-outer-spin-button,
  .Details  .subDetails .GuestInfo  .form-guest form  input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.Details .subDetails .GuestInfo .form-guest form select {
    padding: 10px 10px;
    border-radius: 5px;
}

.Details  .subDetails .GuestInfo  .form-guest form select:focus{
   border-radius: 10px;
}

.Details  .subDetails .GuestInfo .form-guest form .add-guest{
    background-color: white;
    border: transparent;
    color: blue;
    font-size: larger;
    margin: 20px;
    display: block;
}

.Details  .subDetails .GuestInfo .form-guest form .buisness{
 display: block;
}

.Details  .subDetails .GuestInfo .form-guest form .buisnessShort{
    display: none;
}


.Details .subDetails .SpecialRequestInfo{
    transition: all 1s ease-in-out;
    width: 100%;
    height: auto;
    margin: auto;
    background-color: white;
    box-shadow: 0 0 8px rgb(0 0 0 / 18%);
    border-radius: 4px;
    padding:40px;
    margin-top: 100px;
}

.Details  .subDetails .SpecialRequestInfo  .upper-Scroll{
    display: flex;
    justify-content: space-between;
}

.Details  .subDetails .SpecialRequestInfo .all-requests{
    padding: 20px;
}

.Details  .subDetails .SpecialRequestInfo .all-requests form .inputs{
    display: flex;
    border: solid 1px #d7d7d7;
    align-items: center;
    justify-content: space-around;
    width:30%;
    padding: 5px 30px;
    border-radius: 4px;
    margin: 10px;
}

.Details  .subDetails .SpecialRequestInfo .all-requests form {
    display: flex;
    flex-wrap: wrap;
}

.Details  .subDetails .SpecialRequestInfo .all-requests form .other-request{
    width: 100%;
    margin-top: 50px;
}
.Details  .subDetails .SpecialRequestInfo .all-requests form .other-request .other-request-area{
    margin-top: 20px;
    width: 100%;
    padding: 5px;
    height: 200px;
    border-radius: 4px;
    background-color: #f7f7f7;
    border: solid 1px #bebebe;
}


.Details  .subDetails .SpecialRequestInfo .all-requests-short{
    display: none;
}

.Details .subDetails div .pay{
    padding: 15px 50px;
    border-radius: 5px;
    margin: 0 auto;
    width:55%;
     background-image: linear-gradient( 
         -90deg
         , #0074B7, #040404);
     box-shadow: 0 1px 7px 0 rgb(0 0 0 / 20%);
     display: inline-block;
     flex-shrink: 0;
     color: #fff;
     text-transform: uppercase;
     cursor: pointer;
     outline: 0;
     border: 0;
     text-align: center;
     margin-bottom: 40px;
}



.Details .pricing-details{
    width: 30%;
    background-color: transparent;
}

.Details .pricing-details .price-breakup{
    width: 100%;
    height: auto;
    background-color: white;
    display: flex;
    flex-flow: column;
    box-shadow: 0 0 8px rgb(0 0 0 / 18%);
    border-radius: 4px;
}


.Details .pricing-details .price-breakup .total-amount{
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    padding: 15px 20px 15px 20px;
    border-bottom: 1px solid #ededed;
}

.Details .pricing-details .price-breakup .discount{
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    padding: 15px 20px 15px 20px;
    border-bottom: 1px solid #ededed;
}

.Details .pricing-details .price-breakup .discounted-price{
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    padding: 15px 20px 15px 20px;
    border-bottom: 1px solid #ededed;
}

.Details .pricing-details .price-breakup  .taxes{
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    padding: 15px 20px 15px 20px;
    border-bottom: 1px solid #ededed;
}

.Details .pricing-details .price-breakup  .total-amount{
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    padding: 15px 20px 15px 20px;
    border-bottom: 1px solid #ededed;
}


.Details .pricing-details .Deal-Codes{
    margin-top:80px;
    display: flex;
    flex-flow: column;
    box-shadow: 0 0 8px rgb(0 0 0 / 18%);
    border-radius: 4px;
    padding: 20px;
    width: 100%;
    height: auto;
}



.Details .pricing-details .Deal-Codes button{
    padding: 10px 50px;
   border-radius: 34px;
   margin: 0 auto;
   width:65%;
    background-image: linear-gradient( 
        -90deg
        , #0074B7, #040404);
    box-shadow: 0 1px 7px 0 rgb(0 0 0 / 20%);
    display: inline-block;
    flex-shrink: 0;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
    outline: 0;
    border: 0;
    text-align: center;
}


.chkCont {
    border-top: 1px #ccc dotted;
    border-bottom: 1px #ccc dotted;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 5px;
}

.chkCont__col {
    display: flex;
    align-items: center;
    background-color: rgba(242, 242, 242, 0.4);
    padding: 7px 30px;
}
.paymakeFlex.column {
    flex-direction: column;
}

.paymakeFlex{
    display: flex;
    margin-bottom: 5px;
}

.font10 {
    font-size: 10px;
    line-height: 10px;
}

.appendBottom3 {
    margin-bottom: 3px;
}
.font10 {
    font-size: 10px;
    line-height: 10px;
}
.grayText {
    color: #9b9b9b;
}

.blackText {
    color: #000;
}

.latoBlack {
    font-weight: 900;
}
.font24 {
    font-size: 24px;
    line-height: 24px;
}
.appendBottom3 {
    margin-bottom: 3px;
}

.font10 {
    font-size: 10px;
    line-height: 10px;
}
.grayText {
    color: #9b9b9b;
}

.chkCont__night {
    flex: 1;
    margin: 0 15px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 260px;
}

.chkCont__night::before {
    content: "";
    width: 100%;
    border-bottom: 1px #5e5d5d dotted;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.chkCont__night > span {
    padding: 0 10px;
    height: 18px;
    border-radius: 9px;
    border: solid 1px #d8d8d8;
    background-color: #ffffff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    text-transform: uppercase;
    position: relative;
    z-index: 1;
}

.chkCont__col {
    display: flex;
    align-items: center;
    background-color: rgba(242, 242, 242, 0.4);
    padding: 7px 30px;
}
.latoBlack {
    font-weight: 900;
}

.dtHotelReviewNew {
    width: 340px;
    box-shadow: none;
    background-color: transparent;
    margin-top: 10px;
}

.fcDetails {
    width: 368px;
    background-color: #fff;
    text-align: left;
    z-index: 1;
    margin-top: -12px;
}

.fcDetails__bar {
    height: 24px;
    box-shadow: 1px 1px 2px -1px rgb(163 155 155 / 22%);
    border: solid 1px #0174b687;
    background-color: #ffffff;
    overflow: hidden;
}

.fcWidth80 {
    width: 80%;
}

.fcDetails__bar--complete {
    height: 100%;
    background-image: linear-gradient( 
260deg
, #003B73,#0074B7);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px;
}

.fcDetails__bar-oval {
    display: inline-block;
    width: 16px;
    height: 16px;
    box-shadow: inset 0 1px 3px 0 rgb(0 0 0 / 50%);
    background-color: #ffffff;
}

.fcDetails__bar-text {
    font-size: 10px;
}

.fcDetails__bottom {
    display: flex;
}

.fcDetails__bottom--left {
    width: 80%;
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
}

.fcDetails__bottom--left > span {
    padding-top: 12px;
    position: relative;
}

.font11 {
    font-size: 11px;
    line-height: 11px;
}

.fcDetails__bottom--left > span.barLine {
    padding-right: 8px;
}
.fcDetails__bottom--left > span {
    padding-top: 12px;
    position: relative;
}

.fcDetails__bottom--left > span.barLine::after {
    width: 1px;
    height: 22px;
    background-color: #79b5d8;
    content: "";
    position: absolute;
    right: 0;
    top: 0;
}
.fcDetails__bottom--right {
    padding-top: 12px;
    display: flex;
    flex-direction: column;
    text-align: right;
    flex: 1;
}

.darkGreyText {
    color: #4a4a4a;
}

.guestDtls__row {
    margin-bottom: 27px;
}
.guestDtls__col.width70 {
    width: 70px;
}
.guestDtls__col.width71 {
    width: 70px;
    margin-left: 10px;
}
.guestDtls__col {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
}

.font11 {
    font-size: 11px;
    line-height: 11px;
}

.appendBottom10 {
    margin-bottom: 10px;
}
.capText {
    text-transform: uppercase;
}

.frmSelectCont {
    position: relative;
}

.frmSelect {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    border: solid 1px #bebebe;
    outline: 0;
    padding: 0 20px 0 10px;
    font-size: 14px;
    font-weight: 400;
    font-family: lato;
    -webkit-appearance: none;
}

.frmSelectCont::after {
    content: "";
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%) rotate(
45deg
);
    border: solid #898b91;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    pointer-events: none;
}
.paymakeFlex .flexOne {
    flex: 1;
}
.guestDtls__col.width247 {
    width: 247px;
}
.frmTextInput {
    width: 100%;
    height: 40px;
    border-radius: 3px !important;
    border: solid 1px #bebebe !important;
    outline: 0;
    padding: 0 10px;
    font-size: 14px;
    font-weight: 400;
    font-family: lato;
}
.guestDtlsTextLbl {
    min-height: 11px;
}
.guestDtls__col.width327 {
    width: 327px;
}
.guestDtls__contact {
    flex-shrink: 0;
    position: relative;
}
.frmSelectCont__contact {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

.frmSelect.frmSelectContact {
    border-radius: 4px 0 0 4px;
}
.frmSelect {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    border: solid 1px #bebebe;
    outline: 0;
    padding: 0 20px 0 10px;
    font-size: 14px;
    font-weight: 400;
    font-family: lato;
    -webkit-appearance: none;
}

.selectedCode {
    display: inline-flex;
    align-items: center;
    flex-shrink: 0;
    height: 40px;
    min-width: 70px;
    max-width: 90px;
    background-color: #fff;
    border: 1px #bebebe solid;
    border-radius: 4px 0 0 4px;
    padding: 0 25px 0 10px;
    font-size: 14px;
    color: #000;
    position: relative;
    overflow: hidden;
    pointer-events: none;
    transition: all 0.3s linear;
}

.selectedCode::after {
    position: absolute;
    right: 8px;
    top: 13px;
    border: solid #898b91;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 2.5px;
    transform: rotate(
45deg
);
    content: "";
    pointer-events: none;
}

.frmTextInput.noLeftBorder {
    border-left: 0 !important;
    border-radius: 0 4px 4px 0 !important;
}

.guestDtls__addBtn{
    color: #016fb0 !important;
    cursor: pointer;
}

.cpnInput {
    border-radius: 4px;
    box-shadow: 0 1px 7px 0 rgb(0 0 0 / 20%);
    background-color: #ffffff;
    border: 1px #fff transparent;
    display: flex;
    overflow: hidden;
}
.cpnInput input[type="text"] {
    padding-left: 15px;
    width: calc(100% - 45px);
    outline: 0;
    border: 0;
    font-size: 16px;
    font-weight: 700;
    color: #4a4a4a;
}
.cpnInput__btn {
    width: 60px;
    height: 48px;
    border-radius: 0 4px 4px 0;
    background-color: #0074b7;
    flex-shrink: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff !important;
}

.cpnCont{
    margin-top: 20px;
}

.upper-Scroll button {
    outline: 0;
}

.twqCr {
    min-width: 90px;
    color: rgb(57, 111, 198) !important;
}
.jwTkAI {
    font-size: 20px;
    line-height: 26px;
    font-weight: bold;
    margin: 8px 0px;

}

.deYlFr {
    font-size: 14px;
    color: #2A2A2E;
    line-height: 20px;
    font-weight: 300;
}

.react-confirm-alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background: rgb(14 14 14 / 90%) !important;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    -ms-align-items: center;
    align-items: center;
    opacity: 0;
    animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.loaderImage {
    width: 200px;
    margin-bottom: 10px;
}
.react-confirm-alert-body > h1 {
    margin-top: 0px;
    color: #555;
    font-size: 25px;
    font-weight: bold;
    line-height: 25px;
}

.react-confirm-alert-button-group {

    justify-content: flex-start;
    margin-top: 20px;
    display: none !important; 
}

.react-confirm-alert-body {
    font-family: Arial, Helvetica, sans-serif;
    width: 350px !important;
    padding: 20px !important;
    text-align: center !important;
    background: rgb(124, 118, 118);
    border-radius: 10px;
    box-shadow: 0 20px 75px rgb(0 0 0 / 13%);
    color: #666;
}

.progress {
	position: relative;
	height: 2px;
	display: block;
	width: 90%;
	background-color: #acece6;
	border-radius: 2px;
	background-clip: padding-box;
	margin: 0.5rem 0 1rem 20px;
	overflow: hidden;
  }
  .progress .determinate {
	position: absolute;
	background-color: inherit;
	top: 0;
	bottom: 0;
	background-color: #26a69a;
	transition: width 0.3s linear;
  }
  .progress .indeterminate {
	background-color: #26a69a;
  }
  .progress .indeterminate:before {
	content: "";
	position: absolute;
	background-color: inherit;
	top: 0;
	left: 0;
	bottom: 0;
	will-change: left, right;
	-webkit-animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395)
	  infinite;
	animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
  }
  .progress .indeterminate:after {
	content: "";
	position: absolute;
	background-color: inherit;
	top: 0;
	left: 0;
	bottom: 0;
	will-change: left, right;
	-webkit-animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1)
	  infinite;
	animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1)
	  infinite;
	-webkit-animation-delay: 1.15s;
	animation-delay: 1.15s;
  }
  
  @-webkit-keyframes indeterminate {
	0% {
	  left: -35%;
	  right: 100%;
	}
	60% {
	  left: 100%;
	  right: -90%;
	}
	100% {
	  left: 100%;
	  right: -90%;
	}
  }
  @keyframes indeterminate {
	0% {
	  left: -35%;
	  right: 100%;
	}
	60% {
	  left: 100%;
	  right: -90%;
	}
	100% {
	  left: 100%;
	  right: -90%;
	}
  }
  @-webkit-keyframes indeterminate-short {
	0% {
	  left: -200%;
	  right: 100%;
	}
	60% {
	  left: 107%;
	  right: -8%;
	}
	100% {
	  left: 107%;
	  right: -8%;
	}
  }
  @keyframes indeterminate-short {
	0% {
	  left: -200%;
	  right: 100%;
	}
	60% {
	  left: 107%;
	  right: -8%;
	}
	100% {
	  left: 107%;
	  right: -8%;
	}
  }

  .backdropNew{
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(0,0,0,0.7) !important;
    z-index: 99999;
  }

  .Err{
    background-color: #ffeaea;
    border-color: #ffa0a0 !important;
}

.appendBottom5 > span{
    margin-right: 5px !important;
}
.btnContinuePayment {
    align-items: center;
    background-image: linear-gradient(96deg, #53b2fe, #065af3);
    border: 0;
    border-radius: 4px;
    box-shadow: 0 1px 7px 0 #0003;
    color: #fff !important;
    cursor: pointer;
    display: inline-flex;
    flex-shrink: 0;
    font-size: 18px;
    font-weight: 900;
    height: 47px !important;
    justify-content: center;
    outline: 0;
    padding: 9px 20px;
    text-align: center;
    text-transform: uppercase;
    width: 310px;
}
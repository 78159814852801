
.aboutPage{
    width: 1200px;
    /* width: 100%; */
    margin: 0 auto;
    text-align: justify;
    margin-bottom: 50px;
    
}

.aboutHeading{
    font-size: 32px;
    line-height: 35px;
    font-weight: 600;
    padding: 0 0 30px 0!important;
    margin: 0!important;
    color: #737373;
}

.aboutPage p {
    line-height: 160%;
    font-size: 16px;
    margin: 0 0 20px 0;
}

.aboutPage h3{
    font-size: 20px;
    line-height: 40px;
    font-weight: 600;
    margin: 0!important;
}

.aboutPage table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
.aboutPage td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
  .StaticSection {
    margin-bottom: 50px !important;
  }  
  .StaticlandingCard {
    border-radius: 10px;
    box-shadow: 0 3px 30px 0 rgb(0 0 0 / 10%);
    background-color: #ffffff;
    padding: 25px 40px 40px;
  }
  .StaticContainer {
    display: flex;
    flex-flow: column;
}
.StaticMainComponent {
    background-color: rgba(241, 241, 241, 0.82);
    height: auto;
    overflow: hidden;
   
}


.MainStaticMainComponentContaint {height: auto;
    overflow: hidden;
    background-image: linear-gradient( 
    180deg
    ,#003b73,#0074b7,#60a3d9,#bfd7ed);
    background-size: 100% 100%;
    min-height: 100vh;
}
.fixed-price-container{
    position: fixed;
    height: 60%;
    width: 400px;
    top: 35% !important;
    left: 60%;
    display: flex;
    flex-flow: column;
    align-items: center;
    z-index: 1 !important;
    transition: all 0.3s ease-in-out;
}

.price-C{
  display: none;
}
.price-container{
    position: absolute;
    top: 4200px;
    /* top: 580%; */
    height: 60%;
    width: 400px;
    left: 60%;
    display: flex;
    flex-flow: column;
    align-items: center;
    z-index: 2;
    border: none;
}



.price-container .SubContainer,.fixed-price-container .SubContainer{

    display: flex;
    flex-flow: column;
    padding: 20px;
    width: 100%;

    border: 1px solid rgb(221, 221, 221);
    border-radius: 12px;
    padding: 24px;
    box-shadow: rgb(0 0 0 / 12%) 0px 6px 16px;
}

.price-container .SubContainer .fixed-price-header, .fixed-price-container  .SubContainer .fixed-price-header{
    display: flex;
    /* justify-content: space-between; */
    /* background-color: #f1f1f1; */
}
.price-container .SubContainer .fixed-price-header   .small-rating-tile,.fixed-price-container .SubContainer .fixed-price-header   .small-rating-tile{
    background-color: #0074B7;
    height: 40px;
    width: 80px;
    text-align: center;
    border-radius: 5px;
}

.price-container .SubContainer .price-inputs form,.fixed-price-container .SubContainer .price-inputs form{
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}


.price-container .SubContainer .price-inputs form input, .fixed-price-container .SubContainer .price-inputs form input{
    width: 100%;
    height: 30px;
    /* margin-top: 20px; */
    padding-top: 15px ;
    padding-bottom: 15px;
    border: thin solid black;
}

.price-container .SubContainer .price-inputs form .check,.fixed-price-container .SubContainer .price-inputs form .check{
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 8px 70px;
    background-color: #deb956;
    color: white;
    border: 1px solid transparent;
    border-radius: 5px;
    font-size: large;
    transition: all 0.3s ease-in-out;
}
.price-container .SubContainer .price-inputs form .check:hover,.fixed-price-container .SubContainer .price-inputs form .check:hover{
    background-color: #000080;
    transition: all 0.3s ease-in-out;
}

.price-container .SubContainer .price-inputs form .payment,.fixed-price-container .SubContainer .price-inputs form .payment{
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 8px 60px;
    color: black;
    border: 1px solid black;
    border-radius: 5px;
    font-size: large;
    background-color: white;
    transition: all 0.3s ease-in-out;
}

.price-container .SubContainer .price-inputs form .payment:hover,.fixed-price-container .SubContainer .price-inputs form .payment:hover{
    background-color: #deb956;
    color: white;
    transition: all 0.3s ease-in-out;
    border:1px solid transparent;
}

.price-container .btns .shareBtn,.fixed-price-container .btns .shareBtn{
    padding: 5px 20px;
    border:1px solid blue;
    color: blue;
    background-color: white;
    border-radius: 15px;
    margin: 5px;
    transition: all 0.3s ease-in-out;
}

.price-container .btns .SaveBtn, .fixed-price-container .btns .SaveBtn{
    padding: 5px 20px;
    border:1px solid rgb(48, 206, 48);
    color: rgb(48, 206, 48);
    background-color: white;
    border-radius: 15px;
    margin: 5px;
    transition: all 0.3s ease-in-out;
 }
 .price-container .btns .AddBtn, .fixed-price-container .btns .AddBtn{
    padding: 5px 20px;
    border:1px solid red;
    color: red;
    background-color: white;
    border-radius: 15px;
    margin: 5px;
    transition: all 0.3s ease-in-out;
}

.price-container .btns .shareBtn:hover,.fixed-price-container .btns .shareBtn:hover{
    border:1px solid blue;
    color: white;
    background-color: blue;
    transition: all 0.3s ease-in-out;
}

.price-container .btns .SaveBtn:hover,.fixed-price-container .btns .SaveBtn:hover{
    border:1px solid rgb(48, 206, 48);
    color: white;
    background-color: rgb(48, 206, 48);
    transition: all 0.3s ease-in-out;
}

 .price-container .btns .AddBtn:hover,.fixed-price-container .btns .AddBtn:hover{
    border:1px solid red;
    color: white;
    background-color: red;
    transition: all 0.3s ease-in-out;
}


.price-container .SubContainer .price-inputs form .date,.fixed-price-container .SubContainer .price-inputs form .date{
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.price-container .SubContainer .price-inputs form .date label,.fixed-price-container .SubContainer .price-inputs form .date label{
    margin: 10px;
}

.price-container .SubContainer .price-inputs form  #Guests,.fixed-price-container .SubContainer .price-inputs form  #Guests{
    width: 80%;
}

.price-container .SubContainer .price-inputs form .date,.fixed-price-container .SubContainer .price-inputs form #room-type
{
    margin-top: 10px;
    width: 80%;
    padding:8px 20px;
    border-radius: 20px;
    text-align: center;
}

@media(max-width:1500px){
    .fixed-price-container .SubContainer .price-inputs form input{
        width: 100%;
        height: 20px;
    }
    .fixed-price-container{
        position: fixed;
        height: 40%;
        width: 400px;
        top: 32%;
        left: 60%;
        display: flex;
        flex-flow: column;
        align-items: center;
        z-index: 1 !important;
        transition: all 0.3s ease-in-out;
    }
}


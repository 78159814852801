.chkContBooking {
    border: 1px #ccc dotted;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 5px;
    margin-top: 20px;
}
.imageContainerBooking {
    width: 450px;
    height: 280px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    margin-left: 30px;
}
.latoBold {
    font-weight: 700;
}
.font16 {
    font-size: 16px;
    line-height: 16px;
}
.latoBold {
    font-weight: 700;
}
.font16 {
    font-size: 16px;
    line-height: 16px;
}
.blueText a {
    color: #008cff;
    font-size: 15px !important;
}
.errorLinkText {
    text-transform: capitalize;
    margin-top: 22px;
}
.Container-TileBooking {
    margin-bottom: 20px;
    display: flex;
    height: 270px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 0 6px 6px 0;
}


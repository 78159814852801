.duration-Container{
    margin-top: 200px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin-bottom: 200px;
    align-items: center;
    background-color:rgba(0,0,0,0.6);
    height: auto;
}
.duration-Container *{
    margin-top: 20px;
    margin-bottom: 20px;
}
.duration-Container .detailsDuration{
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    width: 70%;
    background-color: #f1f1f1 !important;
    padding: 0 40px;
}
#GoBack{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    position: fixed;
    padding: 10px 20px;
    left:0;
    bottom: 300px;
    z-index: 100;
    background-color:white;
}

#GoForward{
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    position: fixed;
    padding: 10px 20px;
    right:0;
    bottom: 300px;
    z-index: 100;
    background-color: white;
}

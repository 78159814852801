.Detail-container{
    display: flex;
    flex-flow: column;
}

.Detail-container .Horizontal-nav{
   margin-top:100px;
    width: 100%;
    position: fixed;
    background: #BFD7ED;
    height: 55px;
    top: -30px;
    z-index: 2;
}

.Detail-container .Horizontal-nav ul.nav-container{
    padding: 11px 0;
    position: sticky;
    position: -webkit-sticky;
    max-width: 1300px;
    padding-left: 20px;
    z-index: 2;
    display: flex;
    /* justify-content: flex-start; */
}

.Nav{
    display:none;
}


.Detail-container .Horizontal-nav ul.nav-container li{
    font-size: large;
    margin: 5px;
    margin-left: 15px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}
 

.Detail-container .Horizontal-nav ul.nav-container li a{
    color: black;
    transition: all 0.3s ease-in-out;
}


.Detail-container .Horizontal-nav ul.nav-container li a:hover{
    color: #000080;
    transition: all 0.3s ease-in-out;
}


.Detail-container .hotel-details{
    display: flex;
    flex-flow: column;
    margin-left: 100px;
    width: 50%;
}

.Detail-container .hotel-details img{
    height: 100%;
    width: 100%;
}
.Detail-container .hotel-details h1{
    align-items: center;
}

.Detail-container .hotel-details .Text-Details{
    border: 1px solid #f1f1f1;
    border-radius: 4px;
    margin-top: 50px;
    padding: 40px;
}
.Detail-container .hotel-details .Text-Details p{
    font-size: 15px;
}

.Detail-container .hotel-details .Text-Details ol li{
    list-style: square;
}
.Detail-container .hotel-details .Image-details{
    border:1px solid #f1f1f1;
    padding: 20px;
    margin-top: 240px;
}

.Detail-container .hotel-details .Reviews{
    border:1px solid #f1f1f1;
    padding: 20px;
    display: flex;
    flex-flow: column;
    margin-top: 40px;
}


.Detail-container .hotel-details .Reviews .review-overview-card{
    background-color: #0074B7;
    width: 30%;
    height: 120px;
    border-radius: 5px;
    display: flex;
}

.Detail-container .hotel-details .Reviews .review-overview{
    display: flex;
}

.Detail-container .hotel-details .Reviews .review-overview .review-overview-card{
    display: flex;
    flex-flow: column;
    align-items: center;
}


.Detail-container .hotel-details .Reviews .review-overview .review-overview-card h1,
.Detail-container .hotel-details .Reviews .review-overview .review-overview-card h4,
.Detail-container .hotel-details .Reviews .review-overview .review-overview-card h6{
    color: white;
}

.Detail-container .hotel-details .Reviews .review-overview .progressBar{
    width: 70%;
}

.Detail-container .hotel-details .Reviews .review-overview .progressBar progress{
    width: 100%;
    color: blanchedalmond;
    height: 20px;
}

.Detail-container .hotel-details .Reviews .all-reviews{
    display: flex;
    flex-flow: column;
}

.Detail-container .hotel-details .Reviews .all-reviews .people-reviews{
    margin-top: 40px;
    display: flex;
    flex-flow: row;
    padding: 40px;
}

.Detail-container .hotel-details .Reviews .all-reviews .people-reviews img{
    width: 10%;
    height: 10%;
    margin-right: 15px;
    border-radius: 5px;
}

.Detail-container .hotel-details .Reviews .all-reviews .people-reviews .the-review{
    width: 80%;
    border: 1px solid #f1f1f1;
    border-radius: 4px;
    padding: 20px;
}

.Detail-container .hotel-details .google-map{
    margin-top: 30px;
    height: 550px;
    width: 600px;
}

.Detail-container .hotel-details .form{
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 20px;
}

.Detail-container .hotel-details .form input, .Detail-container .hotel-details .form textarea{
    border: thin solid black;
    border-radius: 4px;
    padding: 1px;
}


.Detail-container .hotel-details .form #Email{
    width: 60%;
}

.Detail-container .hotel-details .form #reviewText{
    padding-top: 0 !important;
    padding-left: 0 !important;
    
    width: 80%;
    height: 200px;
}

.Detail-container .hotel-details .form .submitBtn{
    background-color: #deb956;
    padding: 10px 26px;
    color: white;
    border: 1px solid transparent;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    position: relative;
    left: 65%;
}

.Detail-container .hotel-details .form .submitBtn:hover{
    border: 1px solid #deb956;
    color: #deb956;
    background-color: white;
    transition: all 0.3s ease-in-out;
}


.Detail-container .hotel-details .form input:focus{
    outline: blue;
}

/* @media(max-width:1440px)
{
    .Detail-container .hotel-details .form{
        margin-left: 80px;
        
    }

    .Detail-container .hotel-details .form input{
        margin-top: 12px;
    }
    .Detail-container .hotel-details .form label{
        margin-top: 10px;
    }
} */
